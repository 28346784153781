import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'


export default inject("rootstore", "langstore")(observer((props) => {

    return (
        <div className="ts_breadcrumb">
            {props.rootstore.pathCurrent.map((route, i) => {
                if (props.rootstore.pathCurrent.length === (i + 1) || route.actionType === "none") {

                    return (
                        <p
                            className="ts_breadcrumb_inactive ts_label1"
                            key={route.idMenu}><span>{props.langstore.t(route.titleKey, route.titleString)}</span>
                            {(route.actionType === "none") &&
                            <i className="fas fa-angle-right"></i>
                            }
                        </p>
                    )
                } else {
                    return (
                        <p
                            className="ts_breadcrumb_active ts_label1"
                            onClick={() => props.rootstore.routeChangeWithLink(route.path, route.external, route.target)}
                            key={route.idMenu}><span>{props.langstore.t(route.titleKey, route.titleString)}</span> <i
                            className="fas fa-angle-right"></i></p>
                    )
                }

            })}
        </div>
    )
}));
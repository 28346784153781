import {Request, Response} from '../../system/utilities/network.js';
import {toJS, runInAction} from 'mobx';
import langstore from '../../system/i18n/translator.js';
import {validateExtensionImage} from '../../system/utilities/files.js';
import React from "react";
import Compressor from 'compressorjs';

const portafoglioreclami = async function (params, options)
{
    let loadingKey = 'portafoglioreclami';
    let localParams = this.routeParamsCacheGet("portafoglioreclamiParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        "PageNumber": localParams.PageNumber || 1,
        "da_dt_effettiva_reclamo": localParams.da_dt_effettiva_reclamo || dates.fr,
        "a_dt_effettiva_reclamo": localParams.a_dt_effettiva_reclamo || dates.to,
        'route': 'reclami'
    }

    this.loadingAdd(loadingKey);
    runInAction(() => {
        this.routeParamsCacheSave2('portafoglioreclamiParams', _params);
        this.loadingRemove(loadingKey);
    });
}

const dettaglioReclamo = async function ()
{
    let loadingKey = 'reclamiDetail';
    this.loadingAdd(loadingKey);

    let tmp = (this.routeParamsCurrent.reclamoId).split('_');
    let reclamo = tmp[0].split('-');
    let ordine = tmp[1].split('-');

    let _params = {
        n_anno_reclamo: parseInt(reclamo[0]),
        n_reclamo: parseInt(reclamo[1]),
        n_anno_ordine: parseInt(ordine[0]),
        c_numeratore_ordine: ordine[1],
        n_ordine: parseInt(ordine[2])
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'reclami/dettaglio',
        cacheAge: 0,
        userToken: this.userToken,
        params: _params
    });

    runInAction(() => {
        if (response) {
            this.dataReclamoDettaglio = response.data
        }
        this.loadingRemove(loadingKey);
    });
}

const nuovoReclamoInit = async function ()
{
    // recupero informazioni ordine
    await this.orderdetail();
    this.dataReclamo = {
        section: 1,
        rows: [],
        pictures: [],
        note: ""
    };
}

// Azione combo: con parametro true mostra modale; altrimenti invia reclamo
const nuovoReclamoSend = async function (confirmModal)
{
    if (confirmModal === true) {
        this.modalOpen("ModalConfirmReclamo");
    }
    else {
        let loadingKey = 'nuovoReclamoSend';
        this.loadingAdd(loadingKey);
        this.modalCloseAll();

        let data = toJS(this.dataReclamo);

        if(!data.pictures || data.pictures.length===0){
            this.loadingRemove(loadingKey);
            this.notificationAdd(this.props.langstore.t("reclamo_errore_immagini", "Devi caricare almeno un'immagine"), "reclamo_errore_righe", 2000, "error")
        }

        let _params = {
            'des_reclamo': data.note,
            'persona_riferimento': data.referent,
            'telefono_riferimento': data.referent_tel,
            'email_riferimento': data.referent_email,
            'file': data.pictures,
            'righe': data.rows,
            'titolo': this.dataOrder.dettaglioOrdine.riferimento_cliente,
            'c_cliente': this.dataOrder.dettaglioOrdine.c_cliente
        };

        let response = await this.dataLayer({
            url: this.config.paths.apiURL + 'reclami/add',
            cacheAge: 0,
            userToken: this.userToken,
            params: _params
        });

        runInAction(() => {
            if (response) {
                this.modalOpen("ModalSuccessReclamo");
                this.routeChange("/ordini/dettaglio/"+data.rows[0].n_anno+'/'+data.rows[0].c_numeratore+'/'+data.rows[0].n_ordine);
                this.dataReclamo = [];
            }
            else {
                this.modalOpen("ModalFailReclamo");
                this.routeChange("/ordini/dettaglio/"+data.rows[0].n_anno+'/'+data.rows[0].c_numeratore+'/'+data.rows[0].n_ordine);
            }
            this.loadingRemove(loadingKey);
        });
    }
}

const nuovoReclamoUpdate = function (key, value)
{
    let data = toJS(this.dataReclamo);
    data[key] = value;
    this.dataReclamo = data;

    if (key === "section") {
        setTimeout(() => {
            let element = document.querySelector(".ts_body ");
            element.scrollTop = element.scrollHeight;
        }, 1);

        // alert(element.scrollHeight);
        // element.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }
}

const nuovoReclamoAddPictures = async function (file, store)
{
    store = store ?? this;

    let data = toJS(store.dataReclamo);
    var reader = new FileReader();
    var source = "";

    let size_enable = store.config.upload.max_size;

    // case: file not valid
    if (!validateExtensionImage(file.name)) {
        store.notificationAdd(langstore.t("reclami_imgformat_error", "Il file non è un'immagine"), "nuovoReclamoAddPictures", 2000, "error");
        return;
    }

    new Compressor(file,{
        quality:0.8,
        async success(result) {
            //let blob = await result.text();

            reader.onload = function (e) {

                source = e.target.result;

                let f = data.pictures.find((p) => p.name === file.name);

                // case: file already loaded
                if (f) {
                    store.notificationAdd(langstore.t("image_already_loaded", "Immagine già caricata"), "nuovoReclamoAddPictures", 2000, "error");
                    console.log("image already loaded");
                    return;
                }

                // case: file not valid
                /*if (!validateExtensionImage(file.name)) {
                    store.notificationAdd(langstore.t("reclami_imgformat_error", "Il file non è un'immagine"), "nuovoReclamoAddPictures", 2000, "error");
                    return;
                }*/

                // case: image too large
                if (result.size > size_enable){
                    store.notificationAdd(langstore.t("image_too_large", "La dimensione dell'immagine è troppo grande"), "nuovoReclamoAddPictures", 2000, "error");
                    console.log("image too large");
                    return;
                }
                else {
                    if (store.total_size+result.size > size_enable){
                        store.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "nuovoReclamoAddPictures", 2000, "error");
                        console.log("max size finished");
                        return;
                    }
                    else {
                        store.total_size = store.total_size + result.size
                    }
                }

                let item = {
                    source: source,
                    name: file.name,
                    size: result.size
                }

                data.pictures.push(item);
                store.dataReclamo = data;
            }
            reader.readAsDataURL(result)

        },
        error(error) {
            console.log(error.message)
            store.notificationAdd(langstore.t("upload_image_error", "Errore nel caricamento dell'immagine"), "nuovoReclamoAddPictures", 2000, "error");
        }
    });
}

const nuovoReclamoRemovePictures = async function (name)
{
    let data = toJS(this.dataReclamo);

    data.pictures = data.pictures.filter((v) => {
        return v.name !== name;
    });

    // calcolo lo spazio disponibile corrente
    let current_size=0;
    data.pictures.forEach((item) => {
        current_size = current_size + item.size;
    })
    this.total_size = current_size;

    this.dataReclamo = data;

    // necessario azzerare stato input altrimenti non ricarica la stessa immagine
    // i dati immagine sono gestiti nello store e non nell'input
    let input = document.querySelector("#reclamo_file_input");

    input.value = "";
}

export {
    nuovoReclamoSend,
    portafoglioreclami,
    nuovoReclamoInit,
    nuovoReclamoUpdate,
    nuovoReclamoAddPictures,
    nuovoReclamoRemovePictures,
    dettaglioReclamo
}

import { autorun, extendObservable, toJS, runInAction } from "mobx"
import {viewportCurrent} from "../computed/common";
import config from "../../../config.json"


export default function(store){

	const animationDelay = 500;
	let content = document.querySelector(".ts_body");

	let getContentWidth = ()=>{
		if(content === null){
			content = document.querySelector(".ts_body");
		}
		if(content !== null){
			setTimeout(()=>{
			    runInAction( ()=> {
      				store.uiContentWidth = content.clientWidth;
      				store.uiDocumentWidth = document.body.clientWidth;
			    });
			},animationDelay)
		}
	}

	autorun( ()=> {

		let body = document.body;

		if(store.uiSidebarExpanded === null) {

			if (body.clientWidth > config.viewports.desktop_small) {
				store.uiSidebarExpanded = true;
			}
			else{
				store.uiSidebarExpanded = false;
			}
		}

		if(!store.uiSidebarExpanded) {

			body.classList.add("sidebar-closed");
			body.classList.remove("sidebar-open");

			setTimeout(() => {
				body.classList.add("sidebar-hoverable");
			}, 520);
		}
		else{
			body.classList.add("sidebar-open");
			body.classList.remove("sidebar-closed");
			body.classList.remove("sidebar-hoverable");
		}

		let route = store.route;
		getContentWidth();
	})

	window.onresize = function(event) {
		getContentWidth();
	};


	// todo: trovare evento migliore
	function listenDom(){
		if(content === null){
			getContentWidth();
			setTimeout(()=>{
				listenDom();
			},1000);
		}
	}
	listenDom();
}




import {runInAction} from "mobx";
import {ApolloClient, gql, HttpLink, InMemoryCache} from "@apollo/client";
import ReactGA from "react-ga4";

const makeHttpRequest = async function (config, endpoint, apikey, query, variables)
{
    // TODO prefer forwarding the call to backend instead of pim directly
    const client = new ApolloClient({
        link: new HttpLink({ uri: config.pim.baseurl + '/pimcore-graphql-webservices/' + endpoint + '?apikey=' + apikey }),
        fetchOptions: {
            mode: 'no-cors',
        },
        cache: new InMemoryCache()
    });

    return await client
        .query({query: query, variables})
        .then((res) => {
            return res.data;
        })
        .catch((error) => console.log(error));
}

const extractParents = function (object)
{
    let parents =  [];

    while (object && object.id){
        parents.push({'id': object.id, 'title': object.title});
        object = object.parent;
    }

    return parents.reverse();
}

const parentsToString = function (parents)
{
    let parents_string = '';

    if(parents && parents.length) {
        for (let i = 0; i < parents.length; i++) {
            parents_string = parents_string + parents[i].title + '|';
        }
    }

    return parents_string;
}

/** Schede di montaggio */
const getSchedeMontaggio = async function ()
{
    this.dataSchedeMontaggioCategorie = [];

    let query = gql`
        query getObjectFolder($id_folder: Int!, $defaultLanguage: String) {
            categories: getObjectFolder(id: $id_folder, defaultLanguage: $defaultLanguage) {
                id
                children(objectTypes: ["object"]) {
                    ... on object_ManualeDiMontaggioCategoria {
                        classname
                        id
                        title
                        image {
                            fullpath
                        }
                    }                  
                }
            }
        }
  `;

    let variables = {
        id_folder: this.config.pim.schedeMontaggio.folderId,
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.schedeMontaggio.endpoint,
        this.config.pim.schedeMontaggio.apikey,
        query,
        variables
    );

    runInAction(() => {
        if (res)
            this.dataSchedeMontaggioCategorie = res.categories;
        else
            this.dataSchedeMontaggioCategorie = null
    });
}

const getSchedeMontaggioCategory = async function (params, options)
{
    this.dataSchedeMontaggioCategoria = [];

    let query = gql`
        query getManualeDiMontaggioCategoria(
            $id_categoria: Int!
            $defaultLanguage: String
        ) {
            category: getManualeDiMontaggioCategoria(
                id: $id_categoria
                defaultLanguage: $defaultLanguage
            ) {
                id
                title
                childrenSortBy
                children(objectTypes: ["object"]) {
                    ... on object_ManualeDiMontaggioCategoria {
                        id
                        title
                        image {
                            fullpath
                        }
                        classname
                    }
                    ... on object_ManualeDiMontaggio {
                        id
                        title
                        file {
                            ...assetDetails
                        }
                        localized_file {
                            ...assetDetails
                        }
                        classname
                        order
                    }
                    ... on object_ManualeDiMontaggioVideo {
                        id
                        title
                        file {
                        ...assetDetails
                        }
                        localized_file {
                            ...assetDetails
                        }
                        classname
                        order
                    }
                }
                parent{
                  ...on object_ManualeDiMontaggioCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeDiMontaggioCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeDiMontaggioCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeDiMontaggioCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeDiMontaggioCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeDiMontaggioCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeDiMontaggioCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }    
            }
        }
        
        fragment assetDetails on asset {
            id
            filename
            fullpath
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.schedeMontaggio.endpoint,
        this.config.pim.schedeMontaggio.apikey,
        query,
        variables
    );

    runInAction(() => {
        if (res) {

            let parents = extractParents(res.category.parent);
            let parents_string = parentsToString(parents);

            // console.log(this.language.toUpperCase()+'|Categoria|'+parents_string+''+res.category.title);

            if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
                ReactGA.event({
                    category: "assembly-manuals", action: "show-category", label: this.language.toUpperCase()+'|assembly-manuals|'+parents_string+''+res.category.title+'|category'
                });
            }

            this.dataSchedeMontaggioCategoria = res.category;
        }
        else
            this.dataSchedeMontaggioCategoria = null;
    });
}

const getSchedeMontaggioPdf = async function (params, options)
{
    let query = gql`
        query object_ManualeDiMontaggio(
            $id_categoria: Int!
            $defaultLanguage: String
        ) {
            category: getManualeDiMontaggio(
                id: $id_categoria
                defaultLanguage: $defaultLanguage
            ) {
                id
                title
                file {
                    ...assetDetails
                }
                parent{
                  ...on object_ManualeDiMontaggioCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeDiMontaggioCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeDiMontaggioCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeDiMontaggioCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeDiMontaggioCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeDiMontaggioCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeDiMontaggioCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }           
            }
        }

        fragment assetDetails on asset {
            id
            filename
            fullpath
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.schedeMontaggio.endpoint,
        this.config.pim.schedeMontaggio.apikey,
        query,
        variables
    );

    if (res) {

        let parents = extractParents(res.category.parent);
        let parents_string = parentsToString(parents);

        // console.log(this.language.toUpperCase()+'|Pdf|'+parents_string+''+res.category.title);

        if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
            ReactGA.event({
                category: "assembly-manuals", action: "show-pdf", label: this.language.toUpperCase()+'|assembly-manuals|'+parents_string+''+res.category.title+'|pdf'
            });
        }

        return res.category;
    }
    else
        return null;
}

const getSchedeMontaggioVideo = async function (params, options)
{
    let query = gql`
        query getManualeDiMontaggioCategoria(
            $id_categoria: Int!
            $defaultLanguage: String
        ) {
            category: getManualeDiMontaggioVideo(
                id: $id_categoria
                defaultLanguage: $defaultLanguage
            ) {
                id
                title
                file {
                    ...assetDetails
                } 
                parent{
                  ...on object_ManualeDiMontaggioCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeDiMontaggioCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeDiMontaggioCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeDiMontaggioCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeDiMontaggioCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeDiMontaggioCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeDiMontaggioCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }                        
            }
        }
        
        fragment assetDetails on asset {
            id
            filename
            fullpath
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.schedeMontaggio.endpoint,
        this.config.pim.schedeMontaggio.apikey,
        query,
        variables
    );

    if (res) {

        let parents = extractParents(res.category.parent);
        let parents_string = parentsToString(parents);

        // console.log(this.language.toUpperCase()+'|Video|'+parents_string+''+res.category.title);

        if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
            ReactGA.event({
                category: "assembly-manuals", action: "show-video", label: this.language.toUpperCase()+'|assembly-manuals|'+parents_string+''+res.category.title+'|video'
            });
        }

        return res.category;
    }
    else
        return null;
}

/** Manuali tecnici */
const getTechnicalManuals = async function (params, options)
{
    let query = gql`
        query getObjectFolder(
            $id_folder: Int! 
            $defaultLanguage: String
        ) {
            categories: getObjectFolder(
                id: $id_folder
                defaultLanguage: $defaultLanguage
            ) {
                id
                children(objectTypes: ["object"]) {
                    ... on object_ManualeTecnicoCategoria {
                        classname
                        id
                        title
                        image {
                            fullpath
                        }
                        thumb_w_720
                        parent{
                          ...on object_ManualeTecnicoCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeTecnicoCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeTecnicoCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeTecnicoCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeTecnicoCategoria{
                                            id
                                            title
                                            parent{
                                              ...on object_ManualeTecnicoCategoria{
                                                id
                                                title
                                                parent{
                                                  ...on object_ManualeTecnicoCategoria{
                                                    id
                                                    title
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                    }
                }
            }
        }
    `;

    let variables = {
        id_folder: this.config.pim.technicalManuals.folderId,
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.technicalManuals.endpoint,
        this.config.pim.technicalManuals.apikey,
        query,
        variables
    );

    if (res)
        return res.categories;
    else
        return null;
}

const getTechnicalManualsCategory = async function (params, options)
{
    let query = gql`
        query getManualeTecnicoCategoria($id_categoria: Int!, $defaultLanguage: String) 
        {
            category: getManualeTecnicoCategoria(id: $id_categoria, defaultLanguage: $defaultLanguage) 
            {
                id
                title                
                children(objectTypes: ["object"]) {
                    ... on object_ManualeTecnicoCategoria {
                        id
                        classname
                        title
                        image {
                            fullpath
                        }
                        classname
                        thumb_w_720
                    }
                    ... on object_ManualeTecnicoDocumento {
                        id
                        classname
                        title
                        file {
                            ...assetDetails
                        }
                        classname                    
                    }
                    ... on object_ManualeTecnicoVideo {
                        id
                        classname
                        title
                        file {
                        ...assetDetails
                        }
                        localized_file {
                            ...assetDetails
                        }
                        classname                       
                    }
                    ... on object_ManualeTecnicoGallery {
                        id
                        classname
                        gallery_title
                        gallery {
                            image_title
                            image {
                                ...assetDetails
                            }
                        }
                    }
                }
                parent{
                  ...on object_ManualeTecnicoCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeTecnicoCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeTecnicoCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeTecnicoCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeTecnicoCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeTecnicoCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeTecnicoCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }
        }

        fragment assetDetails on asset {
            id
            filename
            fullpath
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.technicalManuals.endpoint,
        this.config.pim.technicalManuals.apikey,
        query,
        variables
    );

    if (res) {

        let parents = extractParents(res.category.parent);
        let parents_string = parentsToString(parents);

        // console.log(this.language.toUpperCase()+'|'+parents_string+''+res.category.title);

        if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
            ReactGA.event({
                category: "technical-manuals", action: "show-category", label: this.language.toUpperCase()+'|technical-manuals|'+parents_string+''+res.category.title+'|category'
            });
        }

        return res.category;
    }
    else {
        return null;
    }
}

const getTechnicalManualsPdf = async function (params, options)
{
    let query = gql`
        query getManualeTecnicoDocumento(
            $id_categoria: Int!
            $defaultLanguage: String
        ) {
            category: getManualeTecnicoDocumento(
                id: $id_categoria
                defaultLanguage: $defaultLanguage
            ) {
                id
                title
                file {
                    ...assetDetails
                }
                localized_file {
                  ...assetDetails
                }
                parent{
                  ...on object_ManualeTecnicoCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeTecnicoCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeTecnicoCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeTecnicoCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeTecnicoCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeTecnicoCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeTecnicoCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }
        }

        fragment assetDetails on asset {
            id
            filename
            fullpath
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.technicalManuals.endpoint,
        this.config.pim.technicalManuals.apikey,
        query,
        variables
    );

    if (res) {

        let parents = extractParents(res.category.parent);
        let parents_string = parentsToString(parents);

        // console.log(this.language.toUpperCase() + '|' + parents_string + '' + res.category.title);


        if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
            ReactGA.event({
                category: "technical-manuals", action: "show-pdf", label: this.language.toUpperCase()+'|technical-manuals|'+parents_string+''+res.category.title+'|pdf'
            });
        }

        return res.category;
    }
    else {
        return null;
    }
}

const getTechnicalManualsGallery = async function (params, options)
{
    let query = gql`
        query getManualeTecnicoGallery(
            $id_categoria: Int!
            $defaultLanguage: String
        ) {
            category: getManualeTecnicoGallery(
                id: $id_categoria
                defaultLanguage: $defaultLanguage
            ) {
                 ... on object_ManualeTecnicoGallery {
                    id
                    classname
                    title
                    gallery_title
                    gallery {
                        image_title
                        image {
                            ...assetDetails
                        }                        
                    }
                 }
                 parent{
                  ...on object_ManualeTecnicoCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeTecnicoCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeTecnicoCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeTecnicoCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeTecnicoCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeTecnicoCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeTecnicoCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }                             
            }
        }

        fragment assetDetails on asset {
            id
            filename
            fullpath
            assetThumb: fullpath(thumbnail: "manuals-square")
            assetThumbWebP: fullpath(thumbnail: "manuals-square",  format: "webp")
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.technicalManuals.endpoint,
        this.config.pim.technicalManuals.apikey,
        query,
        variables
    );

    if (res) {

        let parents = extractParents(res.category.parent);
        let parents_string = parentsToString(parents);

        // console.log(this.language.toUpperCase() + '|' + parents_string + '' + res.category.title);

        if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
            ReactGA.event({
                category: "technical-manuals", action: "show-gallery", label: this.language.toUpperCase()+'|technical-manuals|'+parents_string+''+res.category.title+'|gallery'
            });
        }

        return res.category;
    }
    else
        return null;
}

const getTechnicalManualsVideo = async function (params, options)
{
    let query = gql`
        query getManualeTecnicoVideo(
            $id_categoria: Int!
            $defaultLanguage: String
        ) {
            category: getManualeTecnicoVideo(
                id: $id_categoria
                defaultLanguage: $defaultLanguage
            ) {
                id
                title
                file {
                    ...assetDetails
                }
                parent{
                  ...on object_ManualeTecnicoCategoria{
                    id
                    title
                    parent{
                      ...on object_ManualeTecnicoCategoria{
                        id
                        title
                        parent{
                          ...on object_ManualeTecnicoCategoria{
                            id
                            title
                            parent{
                              ...on object_ManualeTecnicoCategoria{
                                id
                                title
                                parent{
                                  ...on object_ManualeTecnicoCategoria{
                                    id
                                    title
                                    parent{
                                      ...on object_ManualeTecnicoCategoria{
                                        id
                                        title
                                        parent{
                                          ...on object_ManualeTecnicoCategoria{
                                            id
                                            title
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }                         
            }
        }
        
        fragment assetDetails on asset {
            id
            filename
            fullpath
            mimetype
            filesize
        }
    `;

    let variables = {
        id_categoria: parseInt(this.routeParamsCurrent.assetId),
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.technicalManuals.endpoint,
        this.config.pim.technicalManuals.apikey,
        query,
        variables
    );

    if (res) {

        let parents = extractParents(res.category.parent);
        let parents_string = parentsToString(parents);

        // console.log(this.language.toUpperCase() + '|' + parents_string + '' + res.category.title);

        if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
            ReactGA.event({
                category: "technical-manuals", action: "show-video", label: this.language.toUpperCase()+'|technical-manuals|'+parents_string+''+res.category.title+'|video'
            });
        }

        return res.category;
    }else
        return null;
}

/**  Scheda prodotto */
const getSchedaPprodotto = async function (params, options)
{
    let query = gql`
      query getSchedaPrododotto(
          $id: Int!
          $defaultLanguage: String
      ) {
          datasheet: getSchedaPrododotto(
              id: $id
              defaultLanguage: $defaultLanguage
          ) {
              id
              file {
                  ... on asset {
                      fullpath
                  }
              }
              localized_file {
                  ... on asset {
                      fullpath
                  }
              }
          }
      }
  `;

    let variables = {
        id: this.config.pim.schedaProdotto.folderId,
        defaultLanguage: this.language,
    };

    let res = await makeHttpRequest(
        this.config,
        this.config.pim.schedaProdotto.endpoint,
        this.config.pim.schedaProdotto.apikey,
        query,
        variables
    );

    runInAction(() => {
        if (res)
            this.dataSchedaProdotto = res.datasheet;
        else
            return null;
    });
}

const getModelli = async function (params)
{
    let _params = {
        alsounpub: 1,
        lang: this.language
    };

    if (params) {
        _params.model = params
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/finishes/modelli',
        cacheAge: null,
        params: _params,
        userToken: this.userToken
    });

    return response.data[0]
}

const getMateriali = async function (params)
{
    let _params = {
        lang: this.language
    };

    if (params === 'ante') {
        _params.ante = 1;
    }
    if (params === 'top') {
        _params.top = 1;
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/finishes/materiali',
        cacheAge: null,
        params: _params,
        userToken: this.userToken
    });

    return response.data[0]
}

const getColori = async function (params)
{

}

const getFinishes = async function (params, route)
{
    let _params = {
        lang: this.language
    };

    if (params && params.model) {
        _params.model = params.model
    }

    if (params && params.all) {
        _params.all = 1
    }

    if (params && params.page) {
        _params.page = params.page
    }

    if (params && params.material) {
        _params.material = params.material
        if (route === 'top')
            _params.all = 1
    }

    if (params && params.color) {
        _params.color = params.color
    }

    if (params && params.title) {
        _params.title = params.title
        _params.all = 1
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/finishes/' + route,
        cacheAge: null,
        params: _params,
        userToken: this.userToken
    });

    let model = _params.model ? '|'+_params.model :'';

    // console.log(this.language.toUpperCase()+'|Finiture|'+route+model);

    if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
            category: "finishes", action: "show-finishes", label: this.language.toUpperCase()+'|finishes|'+route+model
        });
    }

    return response.data[0]
}

export {
    makeHttpRequest,
    getSchedeMontaggio,
    getSchedeMontaggioCategory,
    getSchedeMontaggioPdf,
    getSchedeMontaggioVideo,
    getTechnicalManuals,
    getTechnicalManualsCategory,
    getTechnicalManualsPdf,
    getTechnicalManualsGallery,
    getTechnicalManualsVideo,
    getSchedaPprodotto,
    getModelli,
    getMateriali,
    getColori,
    getFinishes,
    extractParents,
    parentsToString
}

import Loadable from '@loadable/component';
import Loading from '../system/views/loading.jsx';

const ModalPratiche = Loadable(() => import(/* webpackChunkName: "Modals0" */ "./modals/modalpratiche.jsx"));
const ModalPdfViewer = Loadable(() => import(/* webpackChunkName: "Modals1" */ './modals/modalPdfViewer.jsx'));
const ModalNoteRiga = Loadable(() => import(/* webpackChunkName: "Modals2" */ "./modals/modalnoteriga.jsx"));
const ModalFatture = Loadable(() => import(/* webpackChunkName: "Modals3" */ "./modals/modalfatture.jsx"));
const ModalEmail = Loadable(() => import(/* webpackChunkName: "Modals4" */ "./modals/modalemail.jsx"));
const ModalConfirmReclamo = Loadable(() => import(/* webpackChunkName: "Modals5" */ "./modals/modalConfirmReclamo.jsx"));
const ModalSuccessReclamo = Loadable(() => import(/* webpackChunkName: "Modals6" */ "./modals/modalSuccessReclamo.jsx"));
const ModalGaranzia = Loadable(() => import(/* webpackChunkName: "Modals7" */ "./modals/modalGaranzia.jsx"));
const ModalPromo = Loadable(() => import(/* webpackChunkName: "Modals8" */ "./modals/modalPromo.jsx"));
const ModalLegenda = Loadable(() => import(/* webpackChunkName: "Modals9" */ "./modals/modalLegenda.jsx"));
const ModalReclamiImmagini = Loadable(() => import(/* webpackChunkName: "Modals10" */ "./modals/modalReclamiImmagini.jsx"));
const ModalFailReclamo = Loadable(() => import(/* webpackChunkName: "Modals11" */ "./modals/modalFailReclamo.jsx"));
const ModalVideo = Loadable(() => import(/* webpackChunkName: "Modals12" */ "./modals/modalVideo.jsx"));
const ModalLangSwitcher = Loadable(() => import(/* webpackChunkName: "Modals13" */ "./modals/modalLangSwitcher.jsx"));
const ModalConfirmNewUser = Loadable(() => import(/* webpackChunkName: "Modals14" */ "./modals/modalConfirmNewUser.jsx"));
const ModalSuccessAssistenza = Loadable(() => import(/* webpackChunkName: "Modals15" */ "./modals/modalSuccessAssistenza.jsx"));
const ModalGuide = Loadable(() => import(/* webpackChunkName: "Modals16" */ './modals/modalGuide.jsx'));
const ModalAssetPim = Loadable(() => import('./modals/pim/modalAssetPim.jsx'));
const ModalMetronLicenses = Loadable(() => import('./modals/modalMetronLicenses.jsx'));
const ModalRecapitiClienti = Loadable(() => import('./modals/modalRecapitiCliente.jsx'));
const ModalAggiornamentoSoftware = Loadable(() => import('./modals/modalAggiornamentoSoftware.jsx'));
const ModalConfermaOrdine = Loadable(() => import(/* webpackChunkName: "Modals17" */ "./modals/modalConfermaOrdine.jsx"));
const ModalConfermaRapidaOrdine = Loadable(() => import(/* webpackChunkName: "Modals17" */ "./modals/modalConfermaRapidaOrdine.jsx"));
const ModalCompanyInfo = Loadable(() => import("./modals/modalCompanyInfo.jsx"));
const ModalLegendaSegnalazioni = Loadable(() => import("./modals/modalLegendaSegnalazioni.jsx"));
const modalAapOtpRejectNotes = Loadable(() => import("./modals/modalAapOtpRejectNotes.jsx"));
const ModalCountdownDetails = Loadable(() => import("./modals/modalCountdownDetails.jsx"));
const ModalPermanentlyDeleteCart = Loadable(() => import("./modals/modalPermanentlyDeleteCart.jsx"));
const ModalPermanentlyDeleteCartWithNotes = Loadable(() => import("./modals/modalPermanentlyDeleteCartWithNotes.jsx"));
const ModalCartCountdownTimerExpired = Loadable(() => import("./modals/modalCartCountdownTimerExpired.jsx"));
const ModalSubscriptionNewsletterVideoAcademy  = Loadable(() => import("./modals/modalSubscriptionNewsletterVideoAcademy.jsx"));
const ModalSendNewsletter  = Loadable(() => import("./modals/modalSendNewsletter.jsx"));
const ModalSendTestNewsletter  = Loadable(() => import("./modals/modalSendTestNewsletter.jsx"));
const ModalPreviewNewsletter  = Loadable(() => import("./modals/modalPreviewNewsletter.jsx"));
const DeleteModalNewsletter = Loadable(() => import("./modals/modalDeleteNewsletter.jsx"));
const ModalConfirmCart = Loadable(() => import("./modals/modalConfirmCart.jsx"));
const ModalClientByUserSelect = Loadable(() => import("./modals/modalClientByUserSelect.jsx"));
const ModalProductDetails = Loadable(() => import("./modals/modalProductDetails.jsx"));
const ModalCommleConfirmOrder = Loadable(() => import("./modals/modalCommleConfirmOrder.jsx"));
const ModalCommleMassiveAddToCart = Loadable(() => import("./modals/modalCommleMassiveAddToCart.jsx"));
const ModalGenericText = Loadable(() => import("./modals/modalGenericText.jsx"));
const ModalEldomOutOfStockAction = Loadable(() => import("./modals/modalEldomOutOfStockAction.jsx"));

let modals = {
    ModalPratiche,
    ModalPdfViewer,
    ModalNoteRiga,
    ModalFatture,
    ModalEmail,
    ModalConfirmReclamo,
    ModalSuccessReclamo,
    ModalGaranzia,
    ModalPromo,
    ModalLegenda,
    ModalReclamiImmagini,
    ModalFailReclamo,
    ModalVideo,
    ModalLangSwitcher,
    ModalConfirmNewUser,
    ModalSuccessAssistenza,
    ModalGuide,
    ModalAssetPim,
    ModalMetronLicenses,
    ModalRecapitiClienti,
    ModalAggiornamentoSoftware,
    ModalConfermaOrdine,
    ModalConfermaRapidaOrdine,
    ModalLegendaSegnalazioni,
    ModalCompanyInfo,
    modalAapOtpRejectNotes,
    ModalCountdownDetails,
    ModalPermanentlyDeleteCart,
    ModalPermanentlyDeleteCartWithNotes,
    ModalCartCountdownTimerExpired,
    ModalConfirmCart,
    ModalClientByUserSelect,
    ModalProductDetails,
    ModalCommleConfirmOrder,
    ModalCommleMassiveAddToCart,
    ModalGenericText,
    ModalSubscriptionNewsletterVideoAcademy,
    ModalSendNewsletter,
    ModalSendTestNewsletter,
    ModalPreviewNewsletter,
    DeleteModalNewsletter,
    ModalEldomOutOfStockAction,
}

export default modals;

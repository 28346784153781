import {runInAction} from 'mobx';

const getLastMessages = async function ()
{
    try {

        if (!this.config.showLastMessages) {
            throw {
                id: 'missingConfigShowLastMessages',
                message: 'Manca la configurazione "showLastMessages" per popolare la sezione "Ultime comunicazioni"'
            };
        }

        let response = await this.dataLayer({
            url: this.config.paths.apiURL + 'comunicazioni?limit=' + this.config.showLastMessages,
            cacheAge: 0,
            userToken: this.userToken
        });

        if (response) {
            runInAction(() => {
                this.dataStatistics.last_messages = response.data;
            });
        }

    } catch (error) {
        this.notificationAdd(error.message, error.id, 5000, "error");
    }

}

export {
    getLastMessages
}



const hashString = function(__string) {
    var hash = 0;
    if (__string.length == 0) {
        return hash;
    }
    for (var i = 0; i < __string.length; i++) {
        var char = __string.charCodeAt(i);
        hash = ((hash<<5)-hash)+char;
        hash = hash & hash; // Convert to 32bit integer
        hash = Math.abs(hash);
    }
    return hash;
}

export {hashString}
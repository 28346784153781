import { useEffect, useState } from 'react';
import {toJS} from "mobx";
import {validateExtensionAttachments} from "System/utilities/files";

const attachmentUpload = async function (file, store, scope = '') {

    const { rootstore, langstore } = store;

    let data = toJS(rootstore.attachments);
    let reader = new FileReader();
    let source = '';

    let size_enable = rootstore.config.upload.max_size;

    // case: file not valid
    if (!validateExtensionAttachments(file.name)) {
        rootstore.notificationAdd(langstore.t("filesUpload_format_error", "Il file non è di un formato permesso."), "filesUpload", 2000, "error");
        return;
    }

    reader.onload = function (e) {

        source = e.target.result;

        // case: image too large
        if (file.size > size_enable){
            rootstore.notificationAdd(langstore.t("attachment_too_large", "La dimensione dell'allegato è troppo grande"), "filesUpload", 2000, "error");
            console.log("attachment too large");
            return;
        }
        else {
            if (rootstore.total_size+file.size > size_enable){
                rootstore.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "filesUpload", 2000, "error");
                console.log("max size finished");
                return;
            }
            else {
                rootstore.total_size = rootstore.total_size + file.size
            }
        }

        let item = {
            source: source,
            name: file.name,
            size: file.size,
            scope: scope
        }

        data.push(item);
        rootstore.attachments = data;

    }

    reader.readAsDataURL(file)
}

const attachmentRemove = async function (name, rootstore, scope = '') {

    let data = toJS(rootstore.attachments);

    data = data.filter((v) => {
        if (v.scope !== scope) return true;
        return v.name !== name;
    });

    let current_size=0;
    data.forEach((item) => {
        current_size = current_size + item.size;
    });

    rootstore.attachments.total_size = current_size;

    rootstore.attachments = data;

    let inputSelector = '#add_attachment_file_input';

    if (scope.includes('oggettistica')) {
        inputSelector = '#add_attachment_commle_file_input';
    }

    let input = document.querySelector(inputSelector);

    input.value = '';

}

const attachmentFiles = async function (files, props, scope = '') {

    const { rootstore, langstore } = props;

    let items = [];

    let size_enable = rootstore.config.upload.max_size;

    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // case: file not valid
        if (!validateExtensionAttachments(file.name)) {
            rootstore.notificationAdd(langstore.t("filesUpload_format_error", "Il file non è di un formato permesso."), "filesUpload", 2000, "error");
            continue;
        }

        const reader = new FileReader();

        reader.onload = function (e) {
            const source = e.target.result;

            // case: image too large
            if (file.size > size_enable){
                rootstore.notificationAdd(langstore.t("attachment_too_large", "La dimensione dell'allegato è troppo grande"), "filesUpload", 2000, "error");
                console.log("attachment too large");
                return;
            }
            else {
                if (rootstore.total_size + file.size > size_enable){
                    rootstore.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "filesUpload", 2000, "error");
                    console.log("max size finished");
                    return;
                }
                else {
                    rootstore.total_size = rootstore.total_size + file.size
                }
            }

            const item = {
                source: source,
                name: file.name,
                size: file.size,
                scope: scope
            };

            items.push(item);
        };

        reader.readAsDataURL(file);
    }

    return items;
}


export {
    attachmentUpload,
    attachmentRemove,
    attachmentFiles
};

import { toJS, runInAction } from "mobx";
import { currencyFormat } from "../../system/utilities/currency.js";
import langstore from "../../system/i18n/translator.js";
import moment from "moment";
import { dateFormat } from "../../system/utilities/time";
import { validateExtensionAttachments} from "System/utilities/files";
import Compressor from "compressorjs";

const addCurrency = (object, childPropName) => {
    const _object = {...object};

    if (typeof childPropName == "string") {
        // if child name is correctly defined
        if (_object[childPropName]) {
            // if child actually exists
            _object[childPropName].DS_im_lordo = _object[childPropName].im_lordo
                ? currencyFormat(
                    _object[childPropName].im_lordo,
                    _object[childPropName].c_valuta
                )
                : "--";
            _object[childPropName].DS_im_netto = _object[childPropName].im_netto
                ? currencyFormat(
                    _object[childPropName].im_netto,
                    _object[childPropName].c_valuta
                )
                : "--";
            _object[childPropName].DS_im_totale = _object[childPropName].im_totale
                ? currencyFormat(
                    _object[childPropName].im_totale,
                    _object[childPropName].c_valuta
                )
                : "--";
        }
    } else {
        _object.DS_im_lordo = _object.im_lordo
            ? currencyFormat(_object.im_lordo, _object.c_valuta)
            : "--";
        _object.DS_im_netto = _object.im_netto
            ? currencyFormat(_object.im_netto, _object.c_valuta)
            : "--";
        _object.DS_im_netto = _object.im_totale
            ? currencyFormat(_object.im_totale, _object.c_valuta)
            : "--";
    }

    return _object;
};

const addCurrencyNetto = (object, childPropName) => {
    const _object = {...object};

    if (typeof childPropName == "string") {
        // if child name is correctly defined
        if (_object[childPropName]) {
            // if child actually exists
            _object[childPropName].DS_im_netto = currencyFormat(
                _object[childPropName].im_netto,
                _object[childPropName].c_valuta
            );
        }
    } else {
        _object.DS_im_netto = currencyFormat(_object.im_netto, _object.c_valuta);
    }

    return _object;
};

const totalizzazioniInit = async function () {
    let loadingKey = "totalizzazioniInit";
    this.loadingAdd(loadingKey);

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/portafoglio/totalizzazioni",
        cacheAge: 0,
        userToken: this.userToken,
    });

    // business logic
    runInAction(() => {
        if (response) {
            this.dataOrders = response.data;
        }
        this.loadingRemove(loadingKey);
    });
};

const orderVestizione = async function (row, index) {
    if (row) {
        let options = {};
        let vestizione = toJS(this.dataOrderVestizione);

        let _params = {
            scheda: row.c_scheda,
            articolo: row.c_articolo_alias,
            magazzino: row.c_magazzino_or,
        };

        // API call
        let response = await this.dataLayer({
            url: this.config.paths.apiURL + "vestizione",
            cacheAge:
                options && options.cacheAge
                    ? options.cacheAge
                    : this.config.cacheAPI.default,
            params: _params,
            userToken: this.userToken,
        });

        // business logic
        runInAction(() => {
            if (response) {
                vestizione[index] = response.data;
                this.dataOrderVestizione = vestizione;
            }
        });
    }
};

const fileListModalOpen = function (docs) {
    runInAction(() => {
        this.dataDocumentInfo = {};
        this.dataDocumentActive = docs;
        this.modalOpen("ModalPratiche");
    });
};

const fileList = async function (params, options) {
    let _params = {
        c_pratica: params.c_pratica,
        c_pratica_guid: params.c_pratica_guid,
    };

    let files = toJS(this.dataDocumentInfo);
    let index = params.c_pratica_guid;
    files[index] = {
        loadingState: "loading",
        data: [],
    };
    this.dataDocumentInfo = files;

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "pratiche/infofiles",
        cacheAge:
            options && options.cacheAge
                ? options.cacheAge
                : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    let sorted = response.data && response.data.info ? response.data.info : [];
    sorted.sort((a, b) => {
        return a.c_versione - b.c_versione || a.c_riga - b.c_riga;
    });

    // business logic
    runInAction(() => {
        if (response) {
            files[index] = {
                loadingState: "complete",
                data: sorted,
            };
            this.dataDocumentInfo = files;
        }
    });
};

const ordiniDaConfermare = async function (params, options) {

  let response2 = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/da-confermare",
    cacheAge: this.config.cacheAPI.default,
    params: {
      "stati_ordine_portale": 10
    },
    userToken: this.userToken,
  });

  if (response2 && response2.data && response2.data.ordini && response2.data.ordini[0]) {
      this.dataOrdersToConfirmDashboard.count = response2.data.ordini[0].TotalRowsCount;
  } else {
      this.dataOrdersToConfirmDashboard.count = 0;
  }
  this.dataOrdersToConfirmDashboard.loading = false;
}

const orders = async function (params, options) {
    let loadingKey = "orderParams";
    let localParams = this.routeParamsCacheGet("orderParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/attivi",
    };

    // spinner
    this.loadingAdd(loadingKey);
    await this.updateEventiOrdini();
    // business logic
    runInAction(() => {
        this.routeParamsCacheSave2("orderParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const portafoglioordini = async function (params, options) {
    let loadingKey = "portafoglioordini";
    let localParams = this.routeParamsCacheGet("portafoglioordiniParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/portafoglio",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("portafoglioordiniParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const ordersets = async function (params, options) {
    if (options && options.noLoad === true) {
        this.uiGridOptionsExpanded = true;
        return;
    }

    let loadingKey = "ordersets";
    let localParams = this.routeParamsCacheGet("ordersetsParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        insieme:
            localParams.insieme ||
            (this.config.orderSets && this.config.orderSets.defaultOrderSet),
        route: "ordine/insiemi",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("ordersetsParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const sostituzioni = async function (params, options) {
    let loadingKey = "ordersSostituzioni";
    let localParams = this.routeParamsCacheGet("sostituzioniParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/sostituzioni",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("sostituzioniParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const sostituzioniAttive = async function (params, options) {
    let loadingKey = "ordersSostituzioniAttive";
    let localParams = this.routeParamsCacheGet("sostituzioniAttiveParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/sostituzioni/attive",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("sostituzioniAttiveParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const ordiniCampioni = async function (params, options) {
    let loadingKey = "ordersOrdiniCampioni";
    let localParams = this.routeParamsCacheGet("ordiniCampioniParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/campioni",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("ordiniCampioniParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const ordiniNoteAssistenza = async function (params, options) {
    let loadingKey = "ordersOrdiniNoteAssistenza";
    let localParams = this.routeParamsCacheGet("ordiniNoteAssistenzaParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/noteassistenza",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("ordiniNoteAssistenzaParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const ordiniGaranzieDaAttivare = async function (params, options) {
    let loadingKey = "ordersOrdiniGaranzieDaAttivare";
    let localParams = this.routeParamsCacheGet("portafoglioordiniParams");
    let dates = this.rangeToDates('year' || this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: dates.fr, // need to force dates to fixed range
        a_dt_ordine_cliente: dates.to,  // need to force dates to fixed range
        chiave_ordinamento: localParams.chiave_ordinamento || "data",
        direzione_ordinamento: localParams.direzione_ordinamento || "DESC",
        route: "ordine/garanzie-da-attivare",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("portafoglioordiniParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const previsioneSpedizioni = async function (params, options) {
    let loadingKey = "orderParams";
    let localParams = this.routeParamsCacheGet("orderParams");

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        route: "ordine/previsionespedizioni",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("orderParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const orderExpo = async function (params, options) {
    let loadingKey = "ordersOrdiniExpo";
    let localParams = this.routeParamsCacheGet("ordiniExpoParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        PageNumber: localParams.PageNumber || 1,
        da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
        a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
        route: "ordine/expo",
    };

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2("ordiniExpoParams", _params);
        this.loadingRemove(loadingKey);
    });
};

const orderdetail = async function (params, options) {
    let loadingKey = "orderDetail";

    this.loadingAdd(loadingKey);

    let _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/dettaglio",
        cacheAge:
            options && options.cacheAge
                ? options.cacheAge
                : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    const data = addCurrency(response.data, "dettaglioOrdine");
    const orderDocuments = data.praticheOrdine || [];
    let otherFiles = [];
    let otherFilesFlat = [];
    let hasNullFolder = false;

    for (var i = 0; i < orderDocuments.length; i++) {
        let _params = {
            c_pratica: orderDocuments[i].c_pratica,
            c_pratica_guid: orderDocuments[i].c_pratica_guid,
        };

        let files = await this.dataLayer({
            url: this.config.paths.apiURL + "pratiche/infofiles",
            cacheAge:
                options && options.cacheAge
                    ? options.cacheAge
                    : this.config.cacheAPI.default,
            params: _params,
            userToken: this.userToken,
        });

        if (files.data && files.data.info && files.data.info.length >= 1) {
            // conferma ordine
            if (orderDocuments[i].c_tipo_documento === "15") {
                data.confermaOrdine = orderDocuments[i];
                data.confermaOrdineFile = null;
                let doc = files.data.info.find((f) => {
                    return f.fg_manuale === 1;
                });
                if (doc) {
                    data.confermaOrdineFile = doc;
                }
            }

                // altri documenti
                // raggruppo per cartella
            // per ogni cartella ordino per versione desc e riga desc + traformo oggetto in array
            else {
                let files3 = [];
                let files2 = files.data.info.reduce((total, f) => {
                    let cartella = f.fg_manuale === 1 || !f.cartella ? "000" : f.cartella;
                    let filename = f.File_name;
                    let extension = null;
                    let viewable = false;
                    let extensions = this.config.viewableExtensions;

                    // let showthisversion = (_file.fg_manuale == "2" || _file.fg_manuale == "3");
                    filename = filename.split("/").pop();
                    filename = filename.split("\\").pop();
                    //filename = filename.replace(/\s+/g, '-').toLowerCase();
                    extension = filename.split(".").pop().toLowerCase();
                    viewable = extensions.includes(extension);
                    f.cartella = cartella;
                    f.DS_filename = filename;
                    f.viewable = viewable;
                    f.pratica = orderDocuments[i];
                    f.ds_tipo_doc = orderDocuments[i].ds_tipo_doc;
                    f.fileLabel = f.ds_file || filename;

                    if (total[cartella]) {
                        total[cartella].push(f);
                    } else {
                        total[cartella] = [f];
                    }

                    if (cartella === "000") {
                        hasNullFolder = true;
                    }

                    return total;
                }, {});
                for (let key in files2) {
                    let sorted = files2[key].sort((a, b) => {
                        return a.c_versione - b.c_versione || a.c_riga - b.c_riga;
                    });
                    files3.push({cartella: key, count: sorted.length, items: sorted});
                    otherFilesFlat = otherFilesFlat.concat(sorted);
                }
                otherFiles.push(files3);
            }
        }
    }

    // data.otherFiles = otherFiles;
    data.otherFilesFlat = otherFilesFlat;
    data.hasNullFolder = hasNullFolder;

    // checkWarranty
    let orderWarranty = await this.dataLayer({
        url: this.config.paths.apiURL + "garanzia/check",
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    runInAction(() => {
        if (orderWarranty) {
            if (orderWarranty.data !== null) {
                this.dataWarranty.exists = true;
                this.dataWarranty.email = orderWarranty.data.e_mail;
                this.dataWarranty.name = orderWarranty.data.ds_nome;
                this.dataWarranty.lastname = orderWarranty.data.ds_cognome;
                this.dataWarranty.lang = orderWarranty.data.c_lingua;
            } else {
                this.dataWarranty.exists = "";
                this.dataWarranty.email = "";
                this.dataWarranty.name = "";
                this.dataWarranty.lastname = "";
                this.dataWarranty.lang = "";
                this.dataWarranty.update = null;
            }
        }
        if (response) {
            this.dataOrder = response.data;
            this.loading = false;
        }
    });

    this.loadingRemove(loadingKey);
};

const otpCheckRetrieveDetails = async function () {
    let loadingKey = "otpCheckRetrieveDetails";
    let url = this.config.paths.apiURL + ( this.routeParamsCurrent.routeObject.id.startsWith('otp-co') ? 'ordine' : 'aap' ) + '-conferma-otp';
    let token = window.location.href.split("/").at(-1); // TODO replace window.location.href with his.routeParamsCurrent.PARAM_NAME
    if(!this.appReady) {
        // for backwards compatibility need to check if second last url part is a lang iso2 code or not
        const urlToInspect = window.location.href.split("/").at(-2).toLowerCase();
        this.language = urlToInspect.length === 2 ? urlToInspect : this.config.languages.languageDefault;
    }

    let _params = {
        c_guid_link: token
    };

    this.loadingAdd(loadingKey);

    let response = await this.dataLayer({
        url: url,
        cacheAge: 0,
        params: _params,
    });

    runInAction(() => {
        if (response) {
            let code = response.data.status_code;
            let r = response.data.ordine_conferma_otp;
            this.verificaOtp.type = 'co';
            if ( response.data.aap_conferma_otp ) {
                r = response.data.aap_conferma_otp;
                this.verificaOtp.type = 'aap';
            }
            this.verificaOtp.statoRisposta = code;
            this.verificaOtp.statoRecord = r.stato_record;
            this.verificaOtp.chiaveOutput = r.chiave_output;
            if (r.stato_record == 'OK') {
                this.verificaOtp.nomeRiferimento = r.riferimento_cliente;
                this.verificaOtp.nomeCliente = r.ragione_sociale;
                this.verificaOtp.idOrdine = `${r.n_anno}-${r.c_numeratore}-${r.n_ordine}`;
                this.verificaOtp.idReclamo = `${r.n_anno_reclamo}-${r.n_reclamo}`;
            }
        }
        this.loadingRemove(loadingKey);
    });
};

const formOtpSubmit = async function () {
    if (this.verificaOtp.otp.length > 3) {
        let loadingKey = 'formOtpSubmit';
        let url = this.config.paths.apiURL + (this.verificaOtp.type === 'aap' ? 'aap' : 'ordine') + '-conferma-otp/update';
        let token = window.location.href.split("/").pop();

        let _params = {
            c_guid_link: token,
            n_pin: this.verificaOtp.otp,
            input_utente: this.verificaOtp.userResponse ?? null,
            input_utente_nota: this.verificaOtp.rejectNotes ?? null
        };

        this.loadingAdd(loadingKey);

        let response = await this.dataLayer({
            url: url,
            cacheAge: 0,
            params: _params,
        });

        runInAction(()=>{
            if (response) {
                let code = response.data.status_code;
                let r = response.data.ordine_conferma_otp;
                this.verificaOtp.type = 'co';
                if ( response.data.aap_conferma_otp ) {
                    r = response.data.aap_conferma_otp;
                    this.verificaOtp.type = 'aap';
                }
                this.verificaOtp.statoRisposta = code;
                this.verificaOtp.statoRecord = r.stato_record;
                this.verificaOtp.chiaveOutput = r.chiave_output;
                if (r.stato_record == 'OK') {
                    this.verificaOtp.nomeRiferimento = r.riferimento_cliente;
                    this.verificaOtp.nomeCliente = r.ragione_sociale;
                    this.verificaOtp.idOrdine = `${r.n_anno}-${r.c_numeratore}-${r.n_ordine}`;
                    this.verificaOtp.idReclamo = `${r.n_anno_reclamo}-${r.n_reclamo}`;
                    localStorage.removeItem('aap_rejected_notes_' + token);
                }
            }
            this.loadingRemove(loadingKey);
        });
    }
};

const otpUpdate = function (val) {
    runInAction(() => {
        this.verificaOtp.otp = val;
    });
};

const aapOtpChoice = function (val) {
    runInAction(() => {
        this.verificaOtp.userResponse = val;
    });
};

const aapOtpRejectNotes = function (val) {
    runInAction(() => {
        this.verificaOtp.rejectNotes = val;
    });
};

const ordertickets = async function (_, options) {
  let loadingKey = "orderTickets";

  this.loadingAdd(loadingKey);

  let _params = {
    n_anno: parseInt(this.routeParamsCurrent.orderYear),
    c_numeratore: this.routeParamsCurrent.orderNumber,
    n_ordine: parseInt(this.routeParamsCurrent.orderId),
  };

  let { data } = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/dettaglio",
    cacheAge:
      options && options.cacheAge
        ? options.cacheAge
        : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken,
  });

  let orderDetails = {
    n_anno: _params.n_anno,
    c_numeratore: _params.c_numeratore,
    n_ordine: _params.n_ordine,
    ds_corrispondente_ordine: data.dettaglioOrdine.ds_corrispondente_ordine,
    email_corrispondente: data.dettaglioOrdine.email_corrispondente,
    fg_stato_ordine: data.dettaglioOrdine.fg_stato_ordine,
    riferimento_cliente: data.dettaglioOrdine.riferimento_cliente,
    fg_stato_ordine_portale: data.dettaglioOrdine.fg_stato_ordine_portale,
    fg_tipo_ordine: data.dettaglioOrdine.fg_tipo_ordine,
    fg_co_visibile: data.dettaglioOrdine.fg_co_visibile,
    fg_gestione_note_tecniche: data.dettaglioOrdine.fg_gestione_note_tecniche
  };

  let tickets = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/note-tecniche",
    cacheAge:
      options && options.cacheAge
        ? options.cacheAge
        : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken,
  });

  runInAction(() => {
    if (tickets) {
      this.dataTickets = {
        orderDetails: orderDetails,
        tickets,
      };
    }
  });

  //Marca lettura se:
  //- NON sto impersonificando
  //- NON letti e provenienti dal ERP
  if (!this.userInfo.impersonatingFrom) {
    runInAction(async () => {
      if (tickets && tickets.data && tickets.data.length) {
        let unreadTickets = tickets.data.filter((t) => !t.fg_letto && t.fg_tipo_mittente === 1);
        if (unreadTickets) {
          let unreadTicketsIds = unreadTickets.map((t) => t.id_messaggio);
          if (unreadTicketsIds) {
            let markedRead = await this.dataLayer({
              url: this.config.paths.apiURL + "ordine/note-tecniche/markread",
              cacheAge: 0,
              params: {
                ids_notetecniche: unreadTicketsIds,
              },
              userToken: this.userToken,
            });
            runInAction(async () => {
              if (markedRead.data) {
                let ticketsUpd = await this.dataLayer({
                  url: this.config.paths.apiURL + "ordine/note-tecniche",
                  cacheID: new Date().toLocaleString(), //bypass cache
                  cacheAge:
                    options && options.cacheAge
                      ? options.cacheAge
                      : this.config.cacheAPI.default,
                  params: _params,
                  userToken: this.userToken,
                });

                runInAction(() => {
                  if (ticketsUpd) {
                    this.dataTickets = {
                      orderDetails: orderDetails,
                      tickets: ticketsUpd,
                    };
                  }
                  this.loading = false;
                });
              }
            });
          }
        }
      }
    });
  }

  this.loadingRemove(loadingKey);
};

const orderticketAddPictures = async function (file, store)
{
  store = store ?? this;

  let data = toJS(store.dataTicket);
  var reader = new FileReader();
  var source = "";

  let size_enable = store.config.upload.max_size;

  // case: file not valid
  // if (!validateExtensionImage(file.name)) {
  if (!validateExtensionAttachments(file.name)) {
    store.notificationAdd(langstore.t("orderticket_attachment_format_error", "Il file non è di un formato permesso."), "orderticketAddAttachments", 2000, "error");
    return;
  }

  new Compressor(file,{
    quality:0.8,
    async success(result) {
      //let blob = await result.text();

      reader.onload = function (e) {

        source = e.target.result;

        let f = data.pictures.find((p) => p.name === file.name);

        // case: file already loaded
        if (f) {
          store.notificationAdd(langstore.t("attachment_already_loaded", "Allegato già caricato"), "nuovoReclamoAddAttachments", 2000, "error");
          console.log("image already loaded");
          return;
        }

        // case: file not valid
        /*if (!validateExtensionImage(file.name)) {
            store.notificationAdd(langstore.t("reclami_imgformat_error", "Il file non è un'immagine"), "nuovoReclamoAddPictures", 2000, "error");
            return;
        }*/

        // case: image too large
        if (result.size > size_enable){
          store.notificationAdd(langstore.t("attachment_too_large", "La dimensione dell'allegato è troppo grande"), "nuovoReclamoAddAttachments", 2000, "error");
          console.log("attachment too large");
          return;
        }
        else {
          if (store.total_size+result.size > size_enable){
            store.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "nuovoReclamoAddAttachments", 2000, "error");
            console.log("max size finished");
            return;
          }
          else {
            store.total_size = store.total_size + result.size
          }
        }

        let item = {
          source: source,
          name: file.name,
          size: result.size
        }

        data.attachments.push(item);
        store.dataTicket = data;
      }
      reader.readAsDataURL(result)

    },
    error(error) {
      console.log(error.message)
      store.notificationAdd(langstore.t("upload_image_error", "Errore nel caricamento dell'immagine"), "orderticketAddPictures", 2000, "error");
    }
  });
}

const orderticketAddAttachments = async function (file, store)
{
  store = store ?? this;

  let data = toJS(store.dataTicket);
  var reader = new FileReader();
  var source = "";

  let size_enable = store.config.upload.max_size;

  // case: file not valid
  // if (!validateExtensionImage(file.name)) {
  if (!validateExtensionAttachments(file.name)) {
    store.notificationAdd(langstore.t("orderticket_attachment_format_error", "Il file non è di un formato permesso."), "orderticketAddAttachments", 2000, "error");
    return;
  }


  reader.onload = function (e) {

    source = e.target.result;

    let f = data.attachments.find((p) => p.name === file.name);

    // case: file already loaded
    if (f) {
      store.notificationAdd(langstore.t("attachment_already_loaded", "Allegato già caricato"), "nuovoReclamoAddAttachments", 2000, "error");
      console.log("image already loaded");
      return;
    }

    // case: file not valid
    /*if (!validateExtensionImage(file.name)) {
				store.notificationAdd(langstore.t("reclami_imgformat_error", "Il file non è un'immagine"), "nuovoReclamoAddPictures", 2000, "error");
				return;
		}*/

    // case: image too large
    if (file.size > size_enable){
      store.notificationAdd(langstore.t("attachment_too_large", "La dimensione dell'allegato è troppo grande"), "nuovoReclamoAddAttachments", 2000, "error");
      console.log("attachment too large");
      return;
    }
    else {
      if (store.total_size+file.size > size_enable){
        store.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "nuovoReclamoAddAttachments", 2000, "error");
        console.log("max size finished");
        return;
      }
      else {
        store.total_size = store.total_size + file.size
      }
    }

    let item = {
      source: source,
      name: file.name,
      size: file.size
    }

    data.attachments.push(item);
    store.dataTicket = data;
  }
  reader.readAsDataURL(file)
}

const orderticketRemovePictures = async function (name)
{
  let data = toJS(this.dataTicket);

  data.pictures = data.pictures.filter((v) => {
    return v.name !== name;
  });

  // calcolo lo spazio disponibile corrente
  let current_size=0;
  data.pictures.forEach((item) => {
    current_size = current_size + item.size;
  })
  this.total_size = current_size;

  this.dataTicket = data;

  // necessario azzerare stato input altrimenti non ricarica la stessa immagine
  // i dati immagine sono gestiti nello store e non nell'input
  let input = document.querySelector("#orderticket_file_input");

  input.value = "";
}

const orderticketRemoveAttachments = async function (name)
{
  let data = toJS(this.dataTicket);

  data.attachments = data.attachments.filter((v) => {
    return v.name !== name;
  });

  // calcolo lo spazio disponibile corrente
  let current_size=0;
  data.attachments.forEach((item) => {
    current_size = current_size + item.size;
  })
  this.total_size = current_size;

  this.dataTicket = data;

  // necessario azzerare stato input altrimenti non ricarica la stessa immagine
  // i dati immagine sono gestiti nello store e non nell'input
  let input = document.querySelector("#orderticket_file_input");

  input.value = "";
}

const orderticketRemoveAllAttachments = async function (){
  this.dataTicket.attachments = [];
}

const getOrderReclami = async function () {
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params2 = {
        PageNumber: 1,
        da_dt_effettiva_reclamo: dates.fr,
        a_dt_effettiva_reclamo: dates.to,
    };

    let response2 = await this.dataLayer({
        url: this.config.paths.apiURL + "reclami",
        cacheAge: this.config.cacheAPI.default,
        params: _params2,
        userToken: this.userToken,
    });

    if (response2) {
        this.dataOrderReclami = response2.data;
    }
};

const orderConfirm = async function (order) {
    let orderStatus = this.orderEventsInfoCurrent;

    if (!orderStatus.canConfirm) {
        alert("Operazione non permessa");
        return false;
    }

    // alert("TEST: azione prevista ma disabilitata per non sovrascrivere i dati");
    let _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/conferma",
        cacheAge: 0,
        params: _params,
        userToken: this.userToken,
    });

    runInAction(() => {
        if (response && response.n_evento) {
            alert("Richiesta inviata");
            this.routeReload();
        } else {
            alert("C'è stato un problema");
        }
    });
};

const orderCancel = async function (order) {
    let orderStatus = this.orderEventsInfoCurrent;

    if (!orderStatus.canCancel) {
        alert("Operazione non permessa");
        return false;
    }

    // alert("TEST: azione prevista ma disabilitata per non sovrascrivere i dati");

    let _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/annulla",
        cacheAge: 0,
        params: _params,
        userToken: this.userToken,
    });

    runInAction(() => {
        if (response && response.n_evento) {
            alert("Richiesta inviata");
            this.routeReload();
        } else {
            alert("C'è stato un problema");
        }
    });
};

const orderSyncNotifiche = function () {
    let notifications = toJS(this.dataEventiNotifiche);
    let events = toJS(this.dataEventiOrdine);

  //console.log(notifications, events);

    let toUpdate = [];
    let toRemove = [];
    let toInsert = [];

    // elaboro diff

    events.forEach((event) => {
        let cached = notifications.find((c) => {
            return c.n_evento === event.n_evento;
        });

        // evento non in cache: messaggio nuova elaborazione, stato non letto
        if (!cached) {
            event.read = false;
            toInsert.push(event);
        }

            // evento in cache uguale: non fare nulla
        // evento in cache cambia: messaggio cambiamento, non letto
        else if (cached.c_stato !== event.c_stato) {
            event.read = false;
            event.update = true;
            toUpdate.push(event);
        }
    });

    notifications.forEach((n) => {
        let active = events.find((event) => {
            return n.n_evento === event.n_evento;
        });
        if (!active) {
            // evento in cache ma non in diff: se c'è log_errore messaggio errore, se no messaggio successo (?)
            toRemove.push(n);
        }
    });

    // salvo nuovo stato
    this.dataEventiNotifiche = [...toInsert, ...toUpdate];
};

const orderDownloadConferma = async function (params, options) {
    let loadingKey = "orderDownloadConferma";

    // spinner
    this.loadingAdd(loadingKey);

    let _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/dettaglio",
        cacheAge:
            options && options.cacheAge
                ? options.cacheAge
                : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    const data = addCurrency(response.data, "dettaglioOrdine");

    // business logic
    runInAction(() => {
        if (response) {
            this.dataOrder = data;
        }
        this.loadingRemove(loadingKey);
    });
};

const orderDownloadAll = async function (params, options) {
    let loadingKey = "orderDownloadAll";

    // spinner
    this.loadingAdd(loadingKey);

    let _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/dettaglio",
        cacheAge:
            options && options.cacheAge
                ? options.cacheAge
                : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    const data = addCurrency(response.data, "dettaglioOrdine");

    // business logic
    runInAction(() => {
        if (response) {
            this.dataOrder = data;
        }
        this.loadingRemove(loadingKey);
    });
};

const orderQueueSync = async function (params, options) {
    //spinner
    this.loadingAdd(loadingKey);

    let _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/dettaglio",
        cacheAge:
            options && options.cacheAge
                ? options.cacheAge
                : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    // // business logic
    runInAction(() => {
        if (response) {
            this.dataOrder = response.data;
        }
        this.loadingRemove(loadingKey);
    });
};

const view3dModel = async function (params, options) {
    let loadingKey = "view3dModel";
    const {config} = this;
    const {paths} = config;

    // spinner
    this.loadingAdd(loadingKey);

    const _params = {
        n_anno: parseInt(this.routeParamsCurrent.orderYear),
        c_numeratore: this.routeParamsCurrent.orderNumber,
        n_ordine: parseInt(this.routeParamsCurrent.orderId),
    };

    let response = await this.dataLayer({
        url: paths.apiURL + "ordine/3dtoken",
        cacheAge:
            options && options.cacheAge ? options.cacheAge : config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    // business logic
    runInAction(() => {
        this.data3dviewer = response.data;
        this.loadingRemove(loadingKey);
    });
};

const open3dView = async function (params, options) {
    const {orderYear, orderNumber, orderId} = params;

    window.open(
        `#/3dview/${orderYear}/${orderNumber}/${orderId}`,
        `${orderYear}-${orderNumber}-${orderId}`
    );
};

const relations = async function (params, options) {
    let loadingKey = "getRelations";

    if (!(options && options.noLoad === true)) {
        // spinner
        this.loadingAdd(loadingKey);
    }

    let _params = {
        anno: parseInt(params.n_anno),
        numeratore: params.c_numeratore,
        numero: parseInt(params.n_ordine),
        calcolo_dati: params.calcolo_dati,
        dati_ulteriori: params.dati_ulteriori ? true : false,
        visualizza_dati: "sunkey",
        utente_esecuzione: "",
    };

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "common/relazionibof",
        cacheAge:
            options && options.cacheAge
                ? options.cacheAge
                : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken,
    });

    // business logic
    runInAction(() => {
        if (response) {
            const data = {
                ...response.data,
            };

            if (data.dati_ulteriori) {
                data.dati_ulteriori = data.dati_ulteriori.map((d) => {
                    return {
                        ...d,
                        DS_im_importo: currencyFormat(d.im_importo, d.c_valuta),
                    };
                });
            }

            this.dataOrderRelations = data;
        }
        this.loadingRemove(loadingKey);
    });
};

const formatDateBasedOnZonaSpedizione = function (
    dataConsegnaOrdine,
    zonaSpedizione
) {
    switch (zonaSpedizione) {
        case "S":
            return (
                moment(dataConsegnaOrdine).year() +
                "-" +
                moment(dataConsegnaOrdine).isoWeek()
            );
        case "SD":
            return (
                moment(dataConsegnaOrdine).add(1, "w").year() +
                "-" +
                moment(dataConsegnaOrdine).add(1, "w").isoWeek()
            );
        case "SDD":
            return (
                moment(dataConsegnaOrdine).add(2, "w").year() +
                "-" +
                moment(dataConsegnaOrdine).add(2, "w").isoWeek()
            );
        default:
            return dateFormat(dataConsegnaOrdine);
    }
};

const confermaOrdine = async function (params) {
    let _params = {
        n_anno: params.n_anno,
        c_numeratore: params.c_numeratore,
        n_ordine: params.n_ordine,
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/conferma",
        cacheAge: 0,
        params: _params,
        userToken: this.userToken,
    });

    let ordine =
        params.n_anno + "-" + params.c_numeratore + "-" + params.n_ordine;

    if (response.data === true) {
        this.notificationAdd(
            langstore.t(
                "order_confirm_success_ok",
                "Gentile Cliente,\n" +
                "la conferma dell'ordine " +
                ordine +
                " verrà elaborata a breve dai nostri sistemi.\n" +
                "Al prossimo aggiornamento dati del portale clienti, sarà possibile verificare l'effettivo stato dell'ordine. Se la conferma andrà a buon fine, l'ordine sarà confermato (vedrà il cambio di stato).\n" +
                "Entro pochi minuti riceverà una mail con l'esito dell'elaborazione del sistema centrale."
            ),
            "orderConfirmSuccess",
            10000,
            "success"
        );
    } else {
        this.notificationAdd(
            langstore.t(
                "order_confirm_fail_ok",
                "Gentile Cliente.\n" +
                "l'ordine " +
                ordine +
                " non può essere confermato. Per eventuali informazioni aggiuntive, contatti il suo referente aziendale."
            ),
            "orderConfirmFail",
            10000,
            "error"
        );
    }

    this.routeReload();
    this.modalClose();
    this.loading = false;
};

const confermaOrdineRapida = async function (params) {
    this.loading = true;

    if (!params.pin) {
        this.notificationAdd(
            langstore.t("order_confirm_fail", "Errore"),
            "orderConfirmFail",
            2000,
            "error"
        );
        this.loading = false;
        return;
    }

    let _params = {
        pin: params.pin,
        n_anno: params.n_anno,
        c_numeratore: params.c_numeratore,
        n_ordine: params.n_ordine,
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "ordine/conferma",
        cacheAge: 0,
        params: _params,
        userToken: this.userToken,
    });

    if (response && response.data === true) {
        this.notificationAdd(
            langstore.t("order_confirm_success", "Conferma inviata"),
            "orderConfirmSuccess",
            2000,
            "success"
        );
        this.routeReload();
        this.modalClose();
        this.loading = false;
        return true;
    } else {
        this.loading = false;
        return false;
    }
};

const insertTicket = async function (params, noteData, orderDetails) {
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/note-tecniche/insert",
    cacheAge: 0,
    params,
    userToken: this.userToken,
  });

  if (response.data) {
    const updatedData = [
      ...this.dataTickets.tickets.data,
      {
        id_messaggio: Math.random(),
        fg_tipo_mittente: 2,
        dt_messaggio: new Date().toISOString(),
        ds_mittente: noteData.ds_mittente,
        mittente: params.mittente,
        testo: params.testo.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        fg_letto: 0,
        fg_completato: 0,
        allegati: response.data.allegati
      },
    ];
    runInAction(() => {
      this.dataTickets = {
        orderDetails: orderDetails,
        tickets: { data: updatedData },
      };
    });
    this.notificationAdd(
      langstore.t(
        "technical_notes_insert success",
        "La nota è stata inserita con successo."
      ),
      "orderConfirmSuccess",
      10000,
      "success"
    );
  } else {
    this.notificationAdd(
      langstore.t(
        "technical_notes_insert error",
        "Si è verificato un errore durante l'inserimento della nota"
      ),
      "orderConfirmFail",
      2000,
      "error"
    );
  }
};

const markManagedTicket = async function (params) {
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/note-tecniche/markmanaged",
    cacheAge: 0,
    params,
    userToken: this.userToken,
  });

  if (response.data) {
    runInAction(() => {
      let orderDetails = this.dataTickets.orderDetails;
      let updatedData = this.dataTickets.tickets.data.map((t) => {
        if (t.id_messaggio ===params.id_notatecnica){
          t.fg_completato = true;
          t.dt_completatamento = new Date().toISOString();
        }

        return t;
      });
      this.dataTickets = {
        orderDetails: orderDetails,
        tickets: { data: updatedData },
      };
      this.noteMarkedAsManaged = true;
    });
    this.notificationAdd(
      langstore.t(
        "technical_notes_markmanaged_success",
        "La nota è stata completata con successo"
      ),
      "orderConfirmSuccess",
      10000,
      "success"
    );
  } else {
    this.notificationAdd(
      langstore.t(
        "technical_notes_markmanaged_fail",
        "Si è verificato un errore durante il completamento della nota"
      ),
      "orderConfirmFail",
      2000,
      "error"
    );
  }
}

export {
  orders,
  ordersets,
  orderdetail,
  ordertickets,
  orderticketAddPictures,
  orderticketAddAttachments,
  orderticketRemovePictures,
  orderticketRemoveAttachments,
  orderticketRemoveAllAttachments,
  otpCheckRetrieveDetails,
  otpUpdate,
  aapOtpChoice,
  aapOtpRejectNotes,
  formOtpSubmit,
  fileList,
  fileListModalOpen,
  orderVestizione,
  sostituzioni,
  sostituzioniAttive,
  ordiniCampioni,
  ordiniNoteAssistenza,
  ordiniGaranzieDaAttivare,
  portafoglioordini,
  orderConfirm,
  orderCancel,
  orderDownloadAll,
  orderDownloadConferma,
  orderSyncNotifiche,
  view3dModel,
  open3dView,
  relations,
  totalizzazioniInit,
  getOrderReclami,
  orderExpo,
  previsioneSpedizioni,
  confermaOrdine,
  confermaOrdineRapida,
  insertTicket,
  markManagedTicket,
  ordiniDaConfermare
};

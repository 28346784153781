import {toJS} from "mobx";
import {log} from '../../system/utilities/developer.js';

const analyticsEventPush = function(event){
	let events = toJS(this.analytics);
	events.push(event);
}

const analyticsEventSend = function(size){
	console.log("sending events");
}


export {log}

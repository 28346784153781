import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontSize: 22,
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: 13,
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: 13,
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
              root: {
                  backgroundColor: 'white',
              },
            },
        },
        MuiInputBase: {
            styleOverrides: {
              root: {
                  backgroundColor: 'white',
              },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                  maxHeight: '250px',
                }
            }
        },
    },
});

export default theme;
import { runInAction } from "mobx";

const archivioNoteDaLeggere = async function (params, options) {
  let loadingKey = "archivioNoteDaLeggere";
  let localParams = this.routeParamsCacheGet("archivioNoteDaLeggereParams");
  let dates = this.rangeToDates("year5");

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    messaggi_da_leggere: true,
    route: "ordine/e-sostituzioni",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("archivioNoteDaLeggereParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const archivioDaConfermare = async function (params, options) {
  let loadingKey = "archivioDaConfermare";
  let localParams = this.routeParamsCacheGet("archivioDaConfermareParams");
  let dates = this.rangeToDates("year5");

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    stati_ordine_portale: 10,
    route: "ordine/da-confermare",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("archivioDaConfermareParams", _params);
    this.loadingRemove(loadingKey);
  });
};

export {
  archivioNoteDaLeggere,
  archivioDaConfermare
};

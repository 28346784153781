import {runInAction, toJS} from "mobx";
import {validateExtensionImage} from "../../system/utilities/files";
import langstore from "../../system/i18n/translator";
import Compressor from "compressorjs";

const assistenzaAddPictures = async function (file, store) {
    store = store ?? this;

    let data = toJS(store.dataAssistenza.allegati);
    var reader = new FileReader();
    var source = "";
    let size_enable = store.config.upload.max_size;
    let f = data.find((p) => p.name === file.name);

    // case: file not valid
    if (!validateExtensionImage(file.name)) {
        store.notificationAdd(langstore.t("assistenza_imgformat_error", "Il file non è un'immagine"), "assistenzaAddPictures", 2000, "error");
        return;
    }

    // case: file already loaded
    if (f) {
        store.notificationAdd(langstore.t("image_already_loaded", "Immagine già caricata"), "assistenzaAddPictures", 2000, "error");
        console.log("image already loaded");
        return;
    }

    new Compressor(file,{
        quality:0.8,
        async success(result) {

            reader.onload = function (e) {

                source = e.target.result;

                // case: image too large
                if (result.size > size_enable){
                    store.notificationAdd(langstore.t("image_too_large", "La dimensione dell'immagine è troppo grande"), "assistenzaAddPictures", 2000, "error");
                    console.log("image too large");
                    return;
                }
                else {
                    if (store.total_size+result.size > size_enable){
                        store.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "assistenzaAddPictures", 2000, "error");
                        console.log("max size finished");
                        return;
                    }
                    else {
                        store.total_size = store.total_size + result.size
                    }
                }

                let item = {
                    source: source,
                    name: file.name,
                    size: result.size
                }

                data.push(item);
                store.dataAssistenza.allegati = data;
            }
            reader.readAsDataURL(result)

        },
        error(error) {
            console.log(error.message)
            store.notificationAdd(langstore.t("upload_image_error", "Errore nel caricamento dell'immagine"), "assistenzaAddPictures", 2000, "error");
        }
    });
}

const assistenzaRemovePictures = async function (name) {
    let data = toJS(this.dataAssistenza.allegati);

    data = data.filter((item) => {
        return item.name !== name;
    });

    // calcolo lo spazio disponibile corrente
    let current_size=0;
    data.forEach((item) => {
        current_size = current_size + item.size;
    })
    this.total_size = current_size;

    this.dataAssistenza.allegati = data;

    // necessario azzerare stato input altrimenti non ricarica la stessa immagine
    // i dati immagine sono gestiti nello store e non nell'input
    let input = document.querySelector("#assistenza_file_input");

    input.value = "";
}

const assistenzaUpdate = async function (params) {
    let data = toJS(this.dataAssistenza);

    for (let prop in params) {
        data[prop] = params[prop];
    }

    runInAction(() => {
        this.dataAssistenza = data;
    });
}

const assistenzaSend = async function (params) {
    let data = toJS(this.dataAssistenza);

    for(let i in data){
        if (i !== 'allegati' && data[i] === ''){
            this.notificationAdd('Compila tutti i campi obbligatori','new-user-form-error',3000,'error');
            return;
        }
    }

    data.oggetto = 'Portale Clienti Arredo3 - Richiesta Assistenza';

    let _params = {
        data: data
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'utils/assistenza',
        cacheAge: 1,
        params: _params,
        userToken: this.userToken
    });

    runInAction(() => {
        if (response) {
            this.dataAssistenza.nome='';
            this.dataAssistenza.cognome='';
            this.dataAssistenza.email='';
            this.dataAssistenza.telefono='';
            //this.dataAssistenza.sezione='';
            this.dataAssistenza.note='';
            this.dataAssistenza.allegati=[];
            this.modalOpen("ModalSuccessAssistenza");
            this.routeReload(null,{cache:1});
        }
    });
}

export {
    assistenzaAddPictures,
    assistenzaRemovePictures,
    assistenzaSend,
    assistenzaUpdate
}
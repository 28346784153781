import {hashString} from '../utilities/strings'
import {Request, Response} from '../utilities/network';
import langstore from '../../system/i18n/translator.js';
import {openDB} from 'idb'
import {runInAction} from "mobx";
import { loadingRemove } from "System/actions/notifications";

const dataRequest = async function (data) {

    data = data || {};
    let params = data.params || {};
    //let namespace = this.config.paths.namespace + this.config.app.version;
    let namespace = this.fullNamespace;
    let url = data.url;
    let cacheID = data.cacheID ? String(`${namespace}_${data.cacheID}`) : String(`${namespace}_${hashString(url + JSON.stringify(params))}`);
    let cacheAge = data.cacheAge || 1000;
    let now = +new Date();
    let userToken = data.userToken || null;
    let result = {}
    let method = data.method || "POST";

    this.log("Parametri " + JSON.stringify(params), "API");

    // IndexedDB
    const dbPromise = await openDB(namespace, 2, {
        upgrade(db) {
            db.createObjectStore('networkdata');
        }
    });

    runInAction(() => {
        this.networkDBStore = dbPromise;
    });

    let tempData = await dbPromise.get('networkdata', cacheID);
    if (tempData) {
        tempData = JSON.parse(tempData)
    }


    // check if there is stored data
    // let tempData = JSON.parse(localStorage.getItem(cacheID)) || {};

    // if cache and cache is fresh
    if (tempData && tempData.data && tempData.timestamp && ((now - tempData.timestamp) < cacheAge)) {
        result = tempData.data;
        this.log("Sorgente dati cache", "API");
        return result;
    }

    // if no cache or cache is too old
    else {
        try {
            this.log("Sorgente dati network", "API");

            let requestBody = new Request({data: params});

            let headers = {"Content-Type": "application/json"};
            if (userToken !== null) {
                headers["x-access-token"] = userToken;
            }
            let request = {method: method, headers: headers};
            request.body = JSON.stringify(requestBody);
            let _response = await fetch(url, request);
            let response = await _response.json();
            let then = +new Date();
            result = new Response(response);

            this.log(String(then - now) + "ms", "API");

            if (result.logout === false && result.status === 'OK') {
                try {
                    let d = JSON.stringify({
                        data: result,
                        timestamp: +new Date()
                    });
                    await dbPromise.put('networkdata', d, cacheID);
                } catch (e) {
                    this.log("Quota localstorage è piena", "WARNING");
                }
            }

            return result;

        } catch (e) {
            this.log(e, "WARNING");
        }

    }

}


// wrapper for network request with logout and cache reset
const dataLayer = async function (data) {

    let response = await this.dataRequest(data);

    if (response) {

        // logout management
        if (response.logout === true) {
            this.log((response && response.message ? response.message : "Consultare network per i dettagli"), "WARNING");
            await this.userLogout();
            // I really want to notify the user about an AUTH ERROR on logout?
            // this.notificationAdd(langstore.t("user_autherror", "Errore di autenticazione"), 'userformlogin', null, 'error');
            return;
        }

        // unmanaged status
        if (data.rawResponse === true) {
            return response;
        }
        // managed status
        else {
            if (response.status === 'ERROR') {
                return await this.handleResponseErrors(response.message);
            }

            return response;
        }


    } else {
        this.notificationAdd(langstore.t("user_servererror", "Errore server") + ': ' + response.message || 'undefined', 'userformlogin', 3000, 'error');
        this.log((response && response.message ? response.message : "Consultare network per i dettagli"), "ERRORE");

    }

}

const handleResponseErrors = async function (errorCode) {
    switch (errorCode) {
        case 'PASSWORD_EXPIRED':
            this.routeChange('/password-expired');
            break;
        case 'warranty_request_error':
            this.notificationAdd(langstore.t("warranty_request_error"), 'warranty_error', 5000, 'error');
            break;
        case 'warranty_notification_error':
            this.notificationAdd(langstore.t("warranty_notification_error"), 'warranty_error', 5000, 'error');
            break;
        case 'warranty_update_error':
            this.notificationAdd(langstore.t("warranty_update_error"), 'warranty_error', 5000, 'error');
            break;
        case 'USER_OR_PASSWORD_NOT_VALID':
            this.notificationAdd(langstore.t("user_or_password_not_valid_error"), 'user_error', 5000, 'error');
            break;
        default:
            this.notificationAdd(langstore.t("user_apperror", "Errore") + ': ' + errorCode || 'undefined', 'userformlogin', 3000, 'error');
            break;
    }

    //this.loadingClear();
    this.loadingRemove();
    return errorCode;
}

export {
    dataLayer,
    dataRequest,
    handleResponseErrors
}

import React from "react";
import { inject, observer } from "mobx-react";
import {toJS} from "mobx";

export default inject(
    "rootstore"
)(
    observer((props) => {
        const {rootstore} = props;

        const languages = toJS(rootstore.languages);
        let language_selected = '';
        languages.forEach((item) => {
            if (item.iso === rootstore.language) {
                language_selected = item.key;
            }
        })

        return (
            <>
                <div className="lang-switcher">
                    <div className="lang-selected">
                        <div
                            className="t2"
                            onClick={(e) => {
                                e.preventDefault();
                                props.rootstore.modalOpen("ModalLangSwitcher", null)
                            }}>
                            {language_selected}
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

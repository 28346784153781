import React from "react";
import { inject, observer } from "mobx-react";
import Tooltip from "rc-tooltip";
import Version from "./version.jsx";
import Impersonification from "./impersonification.jsx";
import LangSwitcher from "../../application/components/langSwitcher.jsx";
import parse from "html-react-parser";

export default inject(
  "rootstore",
  "langstore"
)(
  observer((props) => {
    const { rootstore, langstore } = props;
    const isComunicazioniActive =
      rootstore.config && rootstore.config.comunicazioni === true;
    const isAclActive =
      rootstore.config && rootstore.config.aclEnabled === true;
    const viewAcl = ((props.rootstore.dataAutorizzazioni || {}).view_acl || {})
      .abilitato;
    const viewComunications = (
      (props.rootstore.dataAutorizzazioni || {}).view_comunications || {}
    ).abilitato;
    const c_utente = rootstore.dataUserDetails.c_utente;
    const ds_utente = rootstore.dataUserDetails.ds_utente;

    return (
      <div className="row align-items-center">
        <div className="col-2" id="MobileLogo">
          <img
            onClick={() =>
              props.rootstore.routeChange(props.rootstore.config.routes.index)
            }
            src={props.rootstore.config.paths.logoExpanded}
            className={"d-md-block logo-mobile"}
            alt="Company Logo"
          />
        </div>

        <div className="col">
          <div className="ts_user_info row">
            <div className="col-12 text-right">
              <div className="row align-items-center justify-content-end">
                {props.rootstore.viewportCurrent !== "mobile" && (
                  <div className="options-inline">
                    <Version />

                    {rootstore.userInfo &&
                      rootstore.userInfo.impersonatingFrom && (
                        <div className="options-inline">
                          <div className="divider" />
                          <Impersonification />
                        </div>
                      )}

                    {rootstore.userInfo.internal === false &&
                      props.rootstore.userToken !== null && (
                        <div className="options-inline d-none d-xl-flex">
                          <div className="divider" />
                          <div className="mr3 ml3">
                            <div className="t1">
                              {langstore.t(
                                "last_update",
                                "ultimo aggiornamento"
                              )}
                              :
                              <span className="font-weight-bold">
                                {" "}
                                {props.rootstore.last_update}
                              </span>
                            </div>
                            <div className="t1">
                              {langstore.t(
                                "next_update",
                                "prossimo aggiornamento"
                              )}
                              :
                              <span className="font-weight-bold">
                                {" "}
                                {props.rootstore.next_update}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                    <div className="options-inline">
                      {props.rootstore.userToken !== null && (
                        <div className="options-inline">
                          {/*<div className="divider"/>
                                                <div
                                                    className="ts_user_option ml3 mr3"
                                                    onClick={() => props.rootstore.routeChange('/assistenza')}>
                                                    <Tooltip overlay={langstore.t('richiedi_assistenza', 'Richiedi Assistenza')} placement="bottom">
                                                        <i className="fas fa-exclamation-triangle text-danger fa-2x"/>
                                                    </Tooltip>
                                                </div>*/}

                          <div className="divider" />
                          <div
                            className="ts_user_option ml3 mr3"
                            onClick={() => {
                              props.rootstore.routeChange("/profile");
                              if (
                                props.rootstore.viewportCurrent === "mobile" ||
                                props.rootstore.viewportCurrent === "tablet"
                              ) {
                                props.rootstore.uiSidebarToggle(false);
                              }
                            }}
                          >
                            <Tooltip
                              overlay={parse(
                                `${langstore.t(
                                  "profile",
                                  "Gestione profilo"
                                )}<br>${ds_utente}<br>${c_utente}`
                              )}
                              placement="bottom"
                            >
                              <i className="fa fa-user fa-2x" />
                            </Tooltip>
                          </div>
                        </div>
                      )}

                      {rootstore.userInfo.internal === false &&
                        isAclActive &&
                        viewAcl &&
                        props.rootstore.dataUserDetails.c_ruolo !== null &&
                        props.rootstore.dataUserDetails.c_ruolo ===
                          "MASTER" && (
                          <div className="options-inline">
                            <div className="divider" />
                            <div
                              className="ts_user_option ml3 mr3"
                              onClick={() => {
                                props.rootstore.routeChange("/acl");
                                if (
                                  props.rootstore.viewportCurrent ===
                                    "mobile" ||
                                  props.rootstore.viewportCurrent === "tablet"
                                ) {
                                  props.rootstore.uiSidebarToggle(false);
                                }
                              }}
                            >
                              <Tooltip
                                overlay={langstore.t(
                                  "header_users",
                                  "Gestione utenti"
                                )}
                                placement="bottom"
                              >
                                <i className="fa fa-users fa-2x" />
                              </Tooltip>
                            </div>
                          </div>
                        )}

                      {rootstore.userInfo.internal === false &&
                        props.rootstore.userToken !== null &&
                        isComunicazioniActive &&
                        viewComunications &&
                        props.rootstore.viewportCurrent !== "mobile" && (
                          <div className="options-inline">
                            <div className="divider" />
                            <div
                              className="ts_user_option ml3 mr3"
                              onClick={() => {
                                props.rootstore.routeChange("/comunicazioni");
                                if (
                                  props.rootstore.viewportCurrent ===
                                    "mobile" ||
                                  props.rootstore.viewportCurrent === "tablet"
                                ) {
                                  props.rootstore.uiSidebarToggle(false);
                                }
                              }}
                            >
                              <Tooltip
                                placement="bottom"
                                overlay={langstore.t(
                                  "header_notifications",
                                  "Notifiche"
                                )}
                              >
                                <i className="fa fa-envelope fa-2x" />
                              </Tooltip>
                            </div>
                          </div>
                        )}

                      <div className="divider" />
                    </div>
                  </div>
                )}

                <div className="options-inline">
                  <LangSwitcher />
                  <div
                    className="ts_user_option mobile-hamburger ml3"
                    onClick={() => props.rootstore.uiSidebarToggle()}
                  >
                    <i className="fas fa-4x fa-bars" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

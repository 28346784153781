import React from 'react'
import {inject, observer} from 'mobx-react'

export default inject("rootstore")(observer((props) => {
    return (
        <div className="ts_loading_wrap justify-content-center align-items-center">
            <div className="sk-folding-cube">
                <div className="sk-cube1 sk-cube"></div>
                <div className="sk-cube2 sk-cube"></div>
                <div className="sk-cube4 sk-cube"></div>
                <div className="sk-cube3 sk-cube"></div>
            </div>
        </div>
    )
}));
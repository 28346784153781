import {runInAction} from "mobx";

const getNoteTecnicheDashboard = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + "note-tecniche/dashboard",
        cacheAge: this.config.cacheAPI.default,
        params: {},
        userToken: this.userToken,
    });

    runInAction(() => {
        if (response) {
            this.dataNoteTecnicheDashboard = response.data;
        }
    });

};


export {
    getNoteTecnicheDashboard
};

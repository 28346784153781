import {runInAction} from 'mobx';

const notificationAdd = function (message, id, time, type) {
    const speed = time || this.config.theme.notificationSpeed;
    const notificationType = type || 'regular';
    let idExisting = false;

    for (var i = 0; i < this.notifications.length; i++) {
        let notification = this.notifications[i];
        if (notification.id === id) {
            idExisting = true;
        }
    }

    if (idExisting === false) {
        runInAction(() => {
            this.notifications.push({
                id: id,
                message: message,
                type: notificationType
            });
        });
    }

    setTimeout(() => {
        runInAction(() => {
            for (var i = 0; i < this.notifications.length; i++) {
                let notification = this.notifications[i];
                if (notification.id === id) {
                    this.notifications.splice(i, 1);
                }
            }
        });
    }, speed);
};

const loadingAdd = function (loadingKey) {
    runInAction(() => {
        this.networkLoading.push(loadingKey);
    });
};

const loadingRemove = function (loadingKey) {
    runInAction(() => {
        this.networkLoading.splice(this.networkLoading.indexOf(loadingKey), 1);
    });
};

const loadingClear = function () {
    runInAction(() => {
        this.networkLoading = [];
    });
};

export {
    notificationAdd,
    loadingAdd,
    loadingRemove,
    loadingClear
}
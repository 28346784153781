import {runInAction} from 'mobx';

const ddtList = async function (params, options) {
    let loadingKey = 'ddtlist';
    let localParams = this.routeParamsCacheGet("ddtParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        "PageNumber": localParams.PageNumber || 1,
        "da_dt_bolla": localParams.da_dt_bolla || dates.fr,
        "a_dt_bolla": localParams.a_dt_bolla || dates.to,
        "route": 'ddt'
    }

    this.loadingAdd(loadingKey);

    runInAction(() => {
        this.routeParamsCacheSave2('ddtParams', _params);
        this.loadingRemove(loadingKey);
    });

    /*let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'ddt',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken
    });

    runInAction(() => {
        if (response) {
            this.dataDDT = response.data;
        }
        this.loadingRemove(loadingKey);
    });*/
}

export {
    ddtList
}
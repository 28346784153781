import React from 'react';
import {inject, observer} from "mobx-react";

export default inject("rootstore", "langstore")(observer((props) => {
    const {rootstore, langstore} = props;
    return (
        <div className="row h-100 align-items-center justify-content-center wip">
            <div className="col-md-8 col-12 text-center p-lg-5">
                <img src="https://www.arredo3.it/wp-content/uploads/2018/10/logo-arredo3-1.jpg" alt="Logo" className="p-5"/>
                <h2 className="display-1 font-weight-bold">Work In Progess</h2><br/>
                <h4 className="pb-5">{langstore.t("wip", "Saremo presto online!")}</h4>
            </div>
        </div>
    )
}))
import Loadable from '@loadable/component';
import Loading from '../system/views/loading.jsx';

const ModalMessage = Loadable(() => import(/* webpackChunkName: "Modals0" */ "./modals/modalmessage.jsx"));
const ModalDefault = Loadable(() => import(/* webpackChunkName: "Modals1" */ "./modals/modaldefault.jsx"));
const ModalAcl = Loadable(() => import(/* webpackChunkName: "Modals2" */ "./modals/modalAcl.jsx"));
const ModalReset = Loadable(() => import(/* webpackChunkName: "Modals3" */ "./modals/modalPasswordReset.jsx"));


let modals = {
    ModalMessage,
    ModalDefault,
    ModalAcl,
    ModalReset
}

export default modals;
import Loadable from "@loadable/component";
import Loading from "../system/views/loading.jsx";

/** DASHBOARD */
const Dashboard = Loadable(() =>
  import(/* webpackChunkName: "View0" */ "./views/dashboard.jsx")
);

/** ANAGRAFICA CLIENTE */
const ClientiArchivio = Loadable(() =>
  import(/* webpackChunkName: "View2" */ "./views/clienti/clientiArchivio.jsx")
);

const ClientiDettaglio = Loadable(() =>
  import(/* webpackChunkName: "View3" */ "./views/clienti/clientiDettaglio.jsx")
);

/** ORDINI */
const OrdiniArchivio = Loadable(() =>
  import(/* webpackChunkName: "View4" */ "./views/ordini/ordiniArchivio.jsx")
);

const OrdiniAttivi = Loadable(() =>
  import(/* webpackChunkName: "View5" */ "./views/ordini/ordiniAttivi.jsx")
);

const OrdiniCampioni = Loadable(() =>
  import(/* webpackChunkName: "View6" */ "./views/ordini/ordiniCampioni.jsx")
);

const ArchiviOrdiniESostituzioniConNoteDaLeggere = Loadable(() =>
  import(
    /* webpackChunkName: "View6" */ "./views/ordini-e-sostituzioni/archivioNoteDaLeggere.jsx"
  )
);

const ArchiviOrdiniESostituzioniDaConfermare = Loadable(() =>
  import(
    /* webpackChunkName: "View6" */ "./views/ordini-e-sostituzioni/archivioDaConfermare.jsx"
  )
);

const OrdiniNoteAssistenza = Loadable(() =>
  import(/* webpackChunkName: "View7" */ "./views/ordini/noteassistenza.jsx")
);

const OrdiniGaranzieDaAttivare = Loadable(() =>
  import(
    /* webpackChunkName: "View7" */ "./views/ordini/garanziedaattivare.jsx"
  )
);

const OrdiniDettaglio = Loadable(() =>
  import(
    /* webpackChunkName: "View10" */ "./views/ordini/dettaglio/ordiniDettaglio.jsx"
  )
);

const OrdiniTickets = Loadable(() =>
  import(
    /* webpackChunkName: "View10" */ "./views/ordini/tickets/ordiniTickets.jsx"
  )
);

const ConfermaOtpRichiestaConfermaOrdine = Loadable(() =>
  import(
    /* webpackChunkName: "View20" */ "./views/standalone/richiestaConfermaOtp.jsx"
  )
);

const ConfermaEldomOutOfstock = Loadable(() =>
  import(
    /* webpackChunkName: "ViewConfermaEldomOutOfstock" */ "./views/standalone/eldomOutOfStock.jsx"
  )
);

/** EXPO */
const Expo = Loadable(() =>
  import(/* webpackChunkName: "View8" */ "./views/ordini/ordiniExpo.jsx")
);

/** PREVISIONI  SPEDIZIONI */
const PrevisioneSpedizioniArchivio = Loadable(() =>
  import(
    /* webpackChunkName: "View9" */ "./views/ordini/previsioneSpedizioneArchivio.jsx"
  )
);

/** POST VENDITA */
const ReclamiArchivio = Loadable(() =>
  import(
    /* webpackChunkName: "View18" */ "./views/post-vendita/reclami/reclamiArchivio.jsx"
  )
);

const ReclamiDettaglio = Loadable(() =>
  import(
    /* webpackChunkName: "View19" */ "./views/post-vendita/reclami/dettaglio/reclamoDettaglio.jsx"
  )
);

const ReclamiNuovoReclamo = Loadable(() =>
  import(
    /* webpackChunkName: "View20" */ "./views/post-vendita/reclami/nuovoReclamo.jsx"
  )
);

const SostituzioniArchivio = Loadable(() =>
  import(
    /* webpackChunkName: "View11" */ "./views/post-vendita/sostituzioni/sostituzioniArchivio.jsx"
  )
);

const SostituzioniAttive = Loadable(() =>
  import(
    /* webpackChunkName: "View12" */ "./views/post-vendita/sostituzioni/sostituzioniAttive.jsx"
  )
);

const FattureNoteAccredito = Loadable(() =>
  import(
    /* webpackChunkName: "View14" */ "./views/post-vendita/noteAccredito.jsx"
  )
);

const AutorizzazioniReso = Loadable(() =>
  import(
    /* webpackChunkName: "View16" */ "./views/post-vendita/autorizzazioniReso.jsx"
  )
);

const ConfermaOtpAap = Loadable(() =>
  import(
    /* webpackChunkName: "View20" */ "./views/standalone/richiestaConfermaOtp.jsx"
  )
);

/** ECOMMERCE */
const ListaProdotti = Loadable(() =>
  import("./views/ecommerce/listaProdotti.jsx")
);

const DettaglioProdotto = Loadable(() =>
  import("./views/ecommerce/dettaglioProdotto.jsx")
);

const Carrello = Loadable(() => import("./views/ecommerce/carrello.jsx"));

const StoricoCarrelli = Loadable(() =>
  import("./views/ecommerce/storicoCarrelli.jsx")
);

const StoricoCarrelloDettaglio = Loadable(() =>
  import("./views/ecommerce/storicoCarrelloDettaglio.jsx")
);

/** COMMECIALE */
const CommleListaProdottiMarketing = Loadable(() =>
  import("./views/commerciale/productsList.jsx")
);

const CommleCarrelloMarketing = Loadable(() =>
  import("./views/commerciale/cart.jsx")
);

const CommleCarrelloCampionari = Loadable(() =>
  import("./views/commerciale/cart.jsx")
);

const CommleListaProdottiCampionarioAnte = Loadable(() =>
  import("./views/commerciale/productsList.jsx")
);

const CommleCarrelloAnte = Loadable(() =>
  import("./views/commerciale/cart.jsx")
);

const CommleListaContrattiCampionari = Loadable(() =>
  import("./views/commerciale/campionari/contractsList.jsx")
);

const CommleListaProdottiCampionari = Loadable(() =>
  import("./views/commerciale/productsList.jsx")
);

const CommleListaOrdiniOggettistica = Loadable(() =>
  import("./views/commerciale/oggettistica/ordersList.jsx")
);

const CommleListaProdottiOggettistica = Loadable(() =>
  import("./views/commerciale/productsList.jsx")
);

const CommleCarrelloOggettistica = Loadable(() =>
  import("./views/commerciale/cart.jsx")
);

const CommleCarrelloParzialeOggettistica = Loadable(() =>
  import("./views/commerciale/cart.jsx")
);

const CommleCarrelloStoricoOrdini = Loadable(() =>
  import("./views/commerciale/cartsList.jsx")
);

/** AMMINISTRAZIONE */
const FattureArchivio = Loadable(() =>
  import(
    /* webpackChunkName: "View13" */ "./views/amministrazione/fattureArchivio.jsx"
  )
);

const DdtArchivio = Loadable(() =>
  import(
    /* webpackChunkName: "View17" */ "./views/amministrazione/ddtArchivio.jsx"
  )
);

/** PROMO */
const PromozioniArchivio = Loadable(() =>
  import(
    /* webpackChunkName: "View21" */ "./views/promozioni/promozioniArchivio.jsx"
  )
);

/** VIRTUAL TOUR */
const VirtualTourScorze = Loadable(() =>
  import(
    /* webpackChunkName: "View22" */ "./views/virtualtour/virtualtour-scorze.jsx"
  )
);

const VirtualTourMilano = Loadable(() =>
  import(
    /* webpackChunkName: "View23" */ "./views/virtualtour/virtualtour-milano.jsx"
  )
);

/** AREA TECNICA */
const SchedeMontaggioHome = Loadable(() =>
  import(
    /* webpackChunkName: "View24" */ "./views/area-tecnica/schede-montaggio/schedeMontaggio.jsx"
  )
);

const SchedeMontaggioCategory = Loadable(() =>
  import(
    /* webpackChunkName: "View25" */ "./views/area-tecnica/schede-montaggio/schedeMontaggioCategory.jsx"
  )
);

const SchedeMontaggioPdf = Loadable(() =>
  import("./views/area-tecnica/schede-montaggio/schedeMontaggioPdf.jsx")
);

const SchedeMontaggioVideo = Loadable(() =>
  import("./views/area-tecnica/schede-montaggio/schedeMontaggioVideo.jsx")
);

const ManualiTecnici = Loadable(() =>
  import("./views/area-tecnica/manuali-tecnici/manualiTecnici.jsx")
);

const ManualiTecniciCategory = Loadable(() =>
  import("./views/area-tecnica/manuali-tecnici/manualiTecniciCategory.jsx")
);

const ManualiTecniciPdf = Loadable(() =>
  import("./views/area-tecnica/manuali-tecnici/manualiTecniciPdf.jsx")
);

const ManualiTecniciVideo = Loadable(() =>
  import("./views/area-tecnica/manuali-tecnici/manualiTecniciVideo.jsx")
);

const ManualiTecniciGallery = Loadable(() =>
  import("./views/area-tecnica/manuali-tecnici/manualiTecniciGallery.jsx")
);

const Finiture = Loadable(() =>
  import("./views/area-tecnica/finiture/finiture.jsx")
);

const FinitureAnte = Loadable(() =>
  import("./views/area-tecnica/finiture/finitureAnte.jsx")
);

const FinitureTop = Loadable(() =>
  import("./views/area-tecnica/finiture/finitureTop.jsx")
);

const FinitureAnteModello = Loadable(() =>
  import("./views/area-tecnica/finiture/finitureAnteModello.jsx")
);

const SchedaProdotto = Loadable(() =>
  import(
    /* webpackChunkName: "View26" */ "./views/area-tecnica/schedaProdotto.jsx"
  )
);

/** AREA SOFTWARE */
const RequistiSoftware = Loadable(() =>
  import(
    /* webpackChunkName: "View27" */ "./views/software/requisitiSoftware.jsx"
  )
);

const MetronInstaller = Loadable(() =>
  import(
    /* webpackChunkName: "View28" */ "./views/software/metronInstaller.jsx"
  )
);

/** SUPPORTO */
const Arredo3Recapiti = Loadable(() =>
  import(/* webpackChunkName: "View29" */ "./views/arredo3/arredo3Recapiti.jsx")
);
const GeneralSellCondition = Loadable(() =>
  import(
    /* webpackChunkName: "View36" */ "./views/arredo3/generalSellCondition.jsx"
  )
);

const Tutorial = Loadable(() =>
  import(/* webpackChunkName: "View30" */ "./views/tutorial/tutorial.jsx")
);

const Assistenza = Loadable(() =>
  import(/* webpackChunkName: "View32" */ "./views/assistenza/assistenza.jsx")
);

const Booking = Loadable(() =>
  import(/* webpackChunkName: "View35" */ "./views/booking/booking.jsx")
);

/** STATISTICHE */
const Statistiche = Loadable(() =>
  import(/* webpackChunkName: "View31" */ "./views/statistiche/statistiche.jsx")
);

/*** ACADEMY **********/
/****** OLD VERSION ***/
const Academy = Loadable(() =>
  import(/* webpackChunkName: "View33" */ "./views/academy/academy.jsx")
);

/****** NEW VERSION ***/
const VideoAcademy = Loadable(() => import("./views/academy/videoList.jsx"));

const VideoAcademyDettaglio = Loadable(() =>
  import("./views/academy/videoAcademyDettaglio.jsx")
);

const VideoAcademyCategoria = Loadable(() =>
  import("./views/academy/videoByCategory.jsx")
);

/** AREA DOWNLOAD */
const AreaDownload = Loadable(() =>
  import(
    /* webpackChunkName: "View34" */ "./views/area-download/areaDownload.jsx"
  )
);
const AreaDownloadPdf = Loadable(() =>
  import("./views/area-download/areaDownloadPdf.jsx")
);

/** ACADEMY NEWSLETTER */
const CreateNewsletter = Loadable(() =>
  import("./views/newsletter/create.jsx")
);
const ListNewsletter = Loadable(() => import("./views/newsletter/list.jsx"));
const NewsletterSubscribers = Loadable(() =>
  import("./views/newsletter/subscribers.jsx")
);
const EditNewsletter = Loadable(() => import("./views/newsletter/edit.jsx"));
const NewsletterSendState = Loadable(() =>
  import("./views/newsletter/sendState.jsx")
);

const views = {
  Dashboard,
  ClientiArchivio,
  ClientiDettaglio,
  OrdiniArchivio,
  OrdiniAttivi,
  OrdiniCampioni,
  ArchiviOrdiniESostituzioniConNoteDaLeggere,
  ArchiviOrdiniESostituzioniDaConfermare,
  ConfermaOtpRichiestaConfermaOrdine,
  ConfermaEldomOutOfstock,
  ConfermaOtpAap,
  OrdiniNoteAssistenza,
  OrdiniGaranzieDaAttivare,
  PrevisioneSpedizioniArchivio,
  Expo,
  OrdiniDettaglio,
  OrdiniTickets,
  SostituzioniArchivio,
  SostituzioniAttive,
  ReclamiArchivio,
  ReclamiNuovoReclamo,
  ReclamiDettaglio,
  ListaProdotti,
  DettaglioProdotto,
  Carrello,
  StoricoCarrelli,
  StoricoCarrelloDettaglio,
  CommleListaProdottiMarketing,
  CommleCarrelloMarketing,
  CommleCarrelloCampionari,
    CommleListaProdottiCampionarioAnte,
    CommleCarrelloAnte,
  CommleListaContrattiCampionari,
  CommleListaProdottiCampionari,
  CommleListaOrdiniOggettistica,
  CommleListaProdottiOggettistica,
  CommleCarrelloOggettistica,
  CommleCarrelloParzialeOggettistica,
  CommleCarrelloStoricoOrdini,
  FattureArchivio,
  FattureNoteAccredito,
  AutorizzazioniReso,
  DdtArchivio,
  PromozioniArchivio,
  VirtualTourScorze,
  VirtualTourMilano,
  SchedeMontaggioHome,
  SchedeMontaggioCategory,
  SchedeMontaggioPdf,
  SchedeMontaggioVideo,
  ManualiTecnici,
  ManualiTecniciCategory,
  ManualiTecniciPdf,
  ManualiTecniciVideo,
  ManualiTecniciGallery,
  SchedaProdotto,
  RequistiSoftware,
  MetronInstaller,
  Arredo3Recapiti,
  GeneralSellCondition,
  Tutorial,
  Statistiche,
  Assistenza,
  Academy,
  VideoAcademy,
  VideoAcademyDettaglio,
  VideoAcademyCategoria,
  AreaDownload,
  Booking,
  AreaDownloadPdf,
  CreateNewsletter,
  ListNewsletter,
  NewsletterSubscribers,
  EditNewsletter,
  NewsletterSendState,
  Finiture,
  FinitureAnte,
  FinitureTop,
  FinitureAnteModello,
};

export default views;

import i18next from 'i18next';
import {observer, extendObservable, computed, runInAction} from 'mobx';
import it_IT from '../i18n/it_IT.json';
import en_US from '../i18n/en_US.json';


class LanguageStore {

    constructor(i18next) {

        let _this = this;

        this.i18next = i18next;
        this.config = {};

        this.i18next.init({
            lng: 'it',
            debug: false,
        }).then((t) => {
        });

        let t = function (id, defaultString) {
            return _this.i18next.t(id, defaultString);
        }

        extendObservable(this, {
            t: t
        })

    }

    getLanguages() {
        return this.i18next.languages;
    }

    async changeLanguage(_languageCode) {
        let _changeLanguage = (languageCode) => {
            this.i18next.changeLanguage(languageCode);
            let newT = function (id, defaultString) {
                return this.i18next.t(id, defaultString);
            }

            runInAction(() => {
                this.t = newT;
            })
        }
        _changeLanguage(_languageCode);
    }

    getLoadedLanguage() {
        return this.i18next.language;
    }

    loadConfig(config) {
        this.config = config;
    }

    addLanguageBundle(language, bundle) {
        i18next.addResourceBundle(language, 'translation', bundle, true, true);
    }

}

const languageStore = new LanguageStore(i18next);

export default languageStore;
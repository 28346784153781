import langstore from "../../system/i18n/translator";

const getMetronLicenses = async function (){
    let _params = {};

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'crm_auth/metron',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    return response.data;
}

const addMetronLicenses = async function(params) {
    let _params = {
        ...params,
        fullname: params.name+' '+params.lastname
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'crm_auth/metron/add',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response.data === 1){
        this.modalClose();
        this.notificationAdd(langstore.t("licenza_attivata", "Licenza attivata"), "addedMetronLicenses", 2000, "success");
        return 1;
    }
    else {
        this.modalClose();
        this.notificationAdd(langstore.t("licenza_attivata", "Licenza non attivata"), "addedMetronLicenses", 2000, "error");
        return 0;
    }
}

const removeMetronLicenses = async function(params) {
    let _params = {
        id: params.id
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'crm_auth/metron/remove',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response.data === 1){
        this.modalClose();
        this.notificationAdd(langstore.t("licenza_disattivata", "Licenza disattivata"), "removedMetronLicenses", 2000, "success");
        return 1;
    }
    else {
        this.modalClose();
        this.notificationAdd(langstore.t("licenza_attivata", "Licenza non disattivata"), "addedMetronLicenses", 2000, "error");
        return 0;
    }
}

export {
    getMetronLicenses,
    addMetronLicenses,
    removeMetronLicenses
}
import { runInAction } from 'mobx';
import { ApolloClient, gql, InMemoryCache, HttpLink } from "@apollo/client";
import ReactGA from "react-ga4";
import config from "../../../config.json";



const getJwt = async function (params) {
    const _params = {
        folder: params,
        c_utente: this.dataUserDetails.c_utente,
    };

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/jwt',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    return response.data;
}

const tutorial = async function (params) {
    // TODO prefer forwarding the call to backend instead of pim directly
    const client = new ApolloClient({
        link: new HttpLink({ uri: this.config.pim.baseurl + '/pimcore-graphql-webservices/' + this.config.pim.tutorial.endpoint + '?apikey=' + this.config.pim.tutorial.apikey }),
        fetchOptions: {
            mode: 'no-cors',
        },
        cache: new InMemoryCache()
    });

    let variables = {
        defaultLanguage: this.language,
    };

    const TUTORIAL = gql`
      query getTutorialListing($defaultLanguage: String) {
          tutorial: getTutorialListing(
              defaultLanguage: $defaultLanguage
          ) {
              edges {
                  node {
                      id
                      video {
                          ...videoTutorial
                      }
                      title
                      color
                      chiave
                  }
              }
              totalCount
          }
      }

      fragment videoTutorial on asset {
          id
          filename
          fullpath
          mimetype
          filesize
      }
  `;

    const GUIDE = gql`
      query getGuidePortaleClientiListing($defaultLanguage: String) {
          guide: getGuidePortaleClientiListing(
              defaultLanguage: $defaultLanguage
          ) {
              edges {
                  node {
                      id
                      title
                      description
                      file {
                          ...guida
                      }
                      file_localized {
                          ...guida
                      }
                      chiave
                  }
              }
          }
      }
      fragment guida on asset {
          id
          filename
          fullpath
          mimetype
          filesize
      }
  `;

    let response1 = await client
        .query({ query: TUTORIAL, variables })
        .then((res) => {
            return res.data.tutorial.edges;
        })
        .catch(error => console.log(error));

    //console.log(response1)

    let response2 = await client
        .query({ query: GUIDE, variables })
        .then((res) => {
            return res.data.guide.edges;
        })
        .catch(error => console.log(error));

    //console.log(response2)

    runInAction(() => {
        if (response1) {
            this.dataPim.tutorial = response1;
        }
        if (response2) {
            if (this.dataUserDetails.logo === '002') {
                response2 = response2.filter(item => item.node.chiave !== 'garanzia')
            }
            if (!this.dataAutorizzazioni.reclami_web.abilitato) {
                response2 = response2.filter(item => item.node.chiave !== 'reclamo')
            }
            this.dataPim.guide = response2;
        }
    });
}

const getAssetsList = async function (params) {
    const _params = {
        c_utente: params.c_utente || null,
        path: params.path || null
    };

    if (params === '/protected/area download' || params === '/protected/listini') {
        _params.path = '/protected/area download|/protected/listini/' + this.dataUserDetails.c_area_listino;
    }

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/list', cacheAge: 0, params: _params, userToken: this.userToken
    });

    runInAction(() => {
        if (response.data.results && response.data.results.countResults > 0) {
            console.log('>>> TODO save results to state var to be able to use into the dashboard card');
            console.log('>>> results ', response.data.results);
        }
    });

    return response.data;
};

const getAsset = async function (params) {
    // TODO prefer forwarding the call to backend instead of pim directly
    let url = this.config.pim.baseurl + '/protected/' + params.asset;

    let jwt = await this.getJwt(params.root);

    let body = new FormData();
    body.append('token', jwt);

    let config = {
        method: 'POST',
        mode: 'cors',
        body: body
    };

    return await fetch(url, config)
        .then(response => response.body)
        .then(stream => new Response(stream, { headers: { "Content-Type": "application/pdf" } }))
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .catch(error => console.log(error));
}

const getAssetVideo = async function (params) {
    const _params = {
        asset: params.asset, folder: params.root
    };

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pim/asset/video', cacheAge: 0, params: _params, userToken: this.userToken
    });

    if (this.config.development.production && this.config.vendors.googleAnalyticsV4ID) {
        ReactGA.event({
            category: "academy", action: "asset", label: params.name
        });
    }

    return response.data.data.hasOwnProperty('mp4') ? response.data : null;
};

const getLastVersionUpdate = async function (params, options) {
    const client = new ApolloClient({
        uri: 'https://pim.arredo3.it/pimcore-graphql-webservices/customer-hub?apikey=8d07ae89e95fa49789f71cfb77f10a45',
        cache: new InMemoryCache()
    });

    const GET_SOFTWARE_UPDATE = gql`
      query getAggiornamentoSoftware(
          $filter: String,
          $defaultLanguage: String
      ){
          getAggiornamentoSoftwareListing(
              defaultLanguage:$defaultLanguage,
              filter: $filter,
              sortBy:["o_id"],
              sortOrder:["DESC"],
              published: true,
              first:1
          ){
              totalCount,
              edges{
                  node{
                      id,
                      versione,
                      contenuto
                  }
              }
          }
      }
  `;

    let variables = {
        "filter": "{\"software\": \"customerhub\"}", defaultLanguage: this.language
    };

    return await client.query({ query: GET_SOFTWARE_UPDATE, variables })
        .then((res) => {
            if (res.data && res.data.getAggiornamentoSoftwareListing && res.data.getAggiornamentoSoftwareListing.edges && res.data.getAggiornamentoSoftwareListing.edges.length > 0) {
                return res.data.getAggiornamentoSoftwareListing.edges[0].node;
            }
        })
        .catch(error => console.log(error));
}

export {
    tutorial,
    getJwt,
    getAssetsList,
    getAsset,
    getAssetVideo,
    getLastVersionUpdate
}

import { runInAction } from "mobx";
import langstore from 'System/i18n/translator.js';

const eldomOutOfStockRetrieveDetails = async function () {

  let loadingKey = "eldomOutOfStockRetrieveDetails";
  this.loadingAdd(loadingKey);

  const result = await this.dataLayer({
    url: this.config.paths.apiURL + 'eldom-out-of-stock',
    params: {
      c_guid: this.routeParamsCurrent.token
    },
    cacheAge: null
  });

  if (!!Object.keys(result.data).length) {
    runInAction(() => {
      this.eldomOutOfStock = result.data;
    });
  }

  this.loadingRemove(loadingKey);

}

const eldomOutOfStockUpdateFeedback = async function (input_cliente) {
  let loadingKey = "eldomOutOfStockUpdateFeedback";
  this.loadingAdd(loadingKey);

  const result = await this.dataLayer({
    url: this.config.paths.apiURL + 'eldom-out-of-stock/update',
    params: {
      c_guid: this.routeParamsCurrent.token,
      input_cliente: input_cliente
    },
    cacheAge: null
  });

  /*
  const result = {
    data: {
      "stato_record": "OK",
      "rec_id": -1,
      "c_art_commerciale_old": "",
      "ds_art_commerciale_old": "",
      "path_art_old": "",
      "c_art_commerciale_new": "",
      "ds_art_commerciale_new": "",
      "path_art_new": "",
      "dt_stock_in": "",
      "c_feedback_cliente": 0,
      "c_lingua": "IT",
      "codice_output": "M00",
      "chiave_output": "stk_out_otp_M00",
      "nota_output": "Ordine aggiornato correttamente"
    }
  };
  */

  if (!!Object.keys(result.data).length) {
    this.modalCloseAll();

    runInAction(() => {
      this.eldomOutOfStock = result.data;
    });
  }


  this.loadingRemove(loadingKey);

}

export {
  eldomOutOfStockRetrieveDetails,
  eldomOutOfStockUpdateFeedback
}
import { runInAction, toJS } from "mobx";
import langstore from '../../system/i18n/translator.js';
import { attachmentFiles } from "../utils/attachmentsManager";

// TODO CODE REVIEW missing translations. Prepare all the "insert into" queries with: insert into web_traduzioni (id_servizio,fg_tipo_utilizzo,contesto,lingua_iso,chiave,traduzione_chiave,dt_creazione,dt_traduzione,utente_traduzione) values('A3PORTCLIENTI',1,'translation','[CODICE_ISO2_LINGUA]','[CHIAVE]','[TRADUZIONE IN LINGUA]',GETDATE(),GETDATE(),'ADMIN')


const getUserSubscriptionNewsletter = async function (value) {
    const loadingKey = "getUserSubscriptionNewsletter";
    this.loadingAdd(loadingKey);

    const _params = {
        c_utente: this.userInfo.c_utente
    }

    const userAction = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/lista-iscritti/user',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (userAction && userAction.data && userAction.data.length > 0) {
        const academyNewsletterSubscription = userAction.data.iscrizioneUtente !== undefined && userAction.data.iscrizioneUtente.tipo_azione === "ISCRIZIONE_NEWSLETTER_ACADEMY";

        runInAction(() => {
            this.userInfo.academyNewsletter = academyNewsletterSubscription;
            this.loading = false;
        });
    }

    this.loadingRemove(loadingKey);

}

const userActionNewsletter = async function (value) {
    const loadingKey = "userActionNewsletter";
    this.loadingAdd(loadingKey);

    const tipo_azione = this.userInfo.academyNewsletter === true ? 'DISISCRIZIONE_NEWSLETTER_ACADEMY' : 'ISCRIZIONE_NEWSLETTER_ACADEMY';

    const _params = {
        c_utente: this.userInfo.c_utente,
        tipo_azione: tipo_azione
    };


    const userAction = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/azioni-utente',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (userAction) {
        runInAction(() => {
            //TODO veridicare se si più eseguire l'iscrizione/disiscrizione con solamente una richiesta e non due come ora accade.
            this.getUserSubscriptionNewsletter();
            this.loading = false;
            this.modalClose()
        });
    }

    this.loadingRemove(loadingKey);
}


const getNewsletterSubscribers = async function () {

    const loadingKey = "getNewsletterSubscribers";
    this.loadingAdd(loadingKey);

    const users = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/lista-iscritti',
        cacheAge: null,
        params: null,
        userToken: this.userToken,
    });

    if (users) {
        runInAction(() => {
            this.newslettersVideoAcademySubscribers = users;
            this.loading = false;
        });
    }

    this.loadingRemove(loadingKey);

}

const getListNewsletterVideoAcademy = async function (params) {
    const loadingKey = "getListNewsletterVideoAcademy";
    this.loadingAdd(loadingKey);

    const userAction = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy',
        cacheAge: null,
        params: params,
        userToken: this.userToken,
    });

    if (userAction) {
        runInAction(() => {
            //TODO veridicare se si più eseguire l'iscrizione/disiscrizione con solamente una richiesta e non due come ora accade.
            this.getUserSubscriptionNewsletter();
            this.loading = false;
            this.modalClose()
        });
    }

    this.loadingRemove(loadingKey);
}

const generateNewsletterVideoAcademy = async function (value, props) {
    this.notificationAdd(langstore.t("senging_newsletter", "Invio newsletter in corso"), "sendingnewsletter");

    const loadingKey = "generateNewsletterVideoAcademy";
    this.loadingAdd(loadingKey);

    let file;

    await attachmentFiles(value.link_allegati, props, 'newsletter')
        .then(result => {
            console.log(result)
            file = result
        })
        .catch(error => {
            this.notificationAdd(langstore.t("erroreestrazionefile", error), "sendingerrorfile");
        });

    const _params = {
        lingua: value.lingua,
        oggetto: value.oggetto,
        titolo: value.titolo,
        descrizione: value.descrizione,
        lista_id_video: value.lista_id_video,
        link_allegati: file
    };

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy/add',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            this.loading = false;
            this.notificationAdd(langstore.t("send_newsletter_complete", "Invio newsletter completato"), "sendgnewsletter");
            this.routeChange("/newsletter-video-academy/lista")
        });
    } else {
        this.notificationAdd(langstore.t("send_newsletter_error", "Errore invio newsletter"), "newsletternosend", null, 'error');
    }

    this.loadingRemove(loadingKey);

}

const editNewsletterVideoAcademy = async function (value, props) {
    this.notificationAdd(langstore.t("modifing_newsletter", "Modifica newsletter in corso"), "modifingnewsletter");

    const loadingKey = "editNewsletterVideoAcademy";
    this.loadingAdd(loadingKey);

    let file;

    await attachmentFiles(value.link_allegati, props, 'newsletter')
        .then(result => {
            console.log(result)
            file = result
        })
        .catch(error => {
            this.notificationAdd(langstore.t("errore_estrazione_file", error), "sendingerrorfile");
        });

    const _params = {
        id: value.id,
        lingua: value.lingua,
        oggetto: value.oggetto,
        titolo: value.titolo,
        descrizione: value.descrizione,
        lista_id_video: value.lista_id_video,
        link_allegati: file
    };

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy/edit',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            this.loading = false;
            this.notificationAdd(langstore.t("modify_newsletter", "Modifica newsletter completato"), "modifynewsletter");
            this.routeChange("/newsletter-video-academy/lista")
        });
    } else {
        this.notificationAdd(langstore.t("edit_newsletter_error", "Errore modifica newsletter"), "newsletternosend", null, 'error');
    }

    this.loadingRemove(loadingKey);

}

const getNewsletters = async function (value) {
    const loadingKey = "getNewsletters";
    this.loadingAdd(loadingKey);

    const _params = {}

    const listNewsletter = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });


    if (listNewsletter) {
        runInAction(() => {
            this.newslettersVideoAcademyList = listNewsletter.data;
            this.loading = false;
        });
    }

    this.loadingRemove(loadingKey);
}

const getNewsletterForEdit = async function (value) {
    const loadingKey = "getNewsletters";
    this.loadingAdd(loadingKey);

    const _params = {}

    const listNewsletter = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    this.videoAcademyList();


    if (listNewsletter) {
        runInAction(() => {
            this.newslettersVideoAcademyList = listNewsletter.data;
            this.loading = false;
        });
    }

    this.loadingRemove(loadingKey);

}

const sendTestNewsletter = async function (value) {
    const loadingKey = "sendTestNewsletter";
    this.loadingAdd(loadingKey);

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy/test',
        cacheAge: null,
        params: value,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            this.loading = false;
            this.notificationAdd(langstore.t("newsletter_test", "Newsletter di test inviata correttamente"), "");
        });
    } else {
        this.notificationAdd(langstore.t("send_newsletter_error", "Errore invio newsletter"), "newsletternosend", null, 'error');
    }

    this.loadingRemove(loadingKey);
}

const sendNewsletter = async function (value) {
    const loadingKey = "sendNewsletter";
    this.loadingAdd(loadingKey);

    const _params = value;

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy/send',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            this.loading = false;
            this.notificationAdd(langstore.t("newsletter_test", "Newsletter inviata correttamente"), "");
        });
    } else {
        this.notificationAdd(langstore.t("send_newsletter_error", "Errore invio newsletter"), "newsletternosend", null, 'error');
    }


    this.loadingRemove(loadingKey);

}

const anteprimaNewsletter = async function (value) {
    const loadingKey = "anteprimaNewsletter";
    this.loadingAdd(loadingKey);

    const _params = {
        id: value,
        c_utente: this.userInfo.c_utente,
        gruppi: '*',
        lingua: this.language,
    }

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy/generate',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            this.loading = false;
            this.modalOpen("ModalPreviewNewsletter", response);
        });
    } else {
        this.notificationAdd(langstore.t("error_generating_preview", "Errore nella generazione dell'anteprima"), "", null, 'error');
    }

    this.loadingRemove(loadingKey);
}

const deleteNewsletter = async function (value) {
    const loadingKey = "deleteNewsletter";
    this.loadingAdd(loadingKey);

    const _params = {
        id: value
    }

    const response = await this.dataLayer({
        url: this.config.paths.apiURL + 'newsletter/newsletter-video-academy/delete',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            const indexToDelete = this.newslettersVideoAcademyList.findIndex(item => item.id === value);
            if (indexToDelete >= 0) this.newslettersVideoAcademyList.splice(indexToDelete, 1);
            else this.notificationAdd(langstore.t("element_id_not_found", "L'elemento con l'ID specificato non è stato trovato."));
            this.loading = false;
            this.notificationAdd(langstore.t(
                "success_deleting_newsletter",
                "newsletter eliminata correttamente"
            ));
        });
    } else {
        this.notificationAdd(langstore.t("error_deleting_preview", "Errore nell'eliminazione dell'anteprima"), "", null, 'error');
    }

    this.loadingRemove(loadingKey);

}

const statusInvioNewsletter = async function () {
    const loadingKey = "statusInvioNewsletter";
    this.loadingAdd(loadingKey);

    const _params = {
        newsletter_id: this.routeParamsCurrent.idNewsletter
    }

    const response = await this.dataLayer({
        url:  this.config.paths.apiURL + "newsletter/newsletter-video-academy/stato-invio",
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
    });

    if (response) {
        runInAction(() => {
            this.dataNewsletter.status_send_newsletter = response.data.newsletter;
            this.loading = false;

        });
    }
    
    this.loadingRemove(loadingKey);
};
const sendNewsletterModalOpen = async function (value) {
    const params = {
        id: value
    }
    runInAction(() => {
        this.modalOpen("ModalSendNewsletter", params);
    });
}

const sendTestNewsletterModalOpen = async function (value) {
    const params = {
        id: value
    }
    runInAction(() => {
        this.modalOpen("ModalSendTestNewsletter", params);
    });
}

const newsletterModalOpen = async function () {
    runInAction(() => {
        this.modalOpen("ModalSubscriptionNewsletterVideoAcademy");
    });
}

const openDeleteModalNewsletter = async function (value) {
    const params = {
        id: value
    }
    runInAction(() => {
        this.modalOpen("DeleteModalNewsletter", params);
    });
}

const openEditModalNewsletter = async function (value) {
    const params = {
        id: value
    }
    runInAction(() => {
        this.modalOpen("EditModalNewsletter", params);
    });
}

export {
    getUserSubscriptionNewsletter,
    userActionNewsletter,
    getNewsletterSubscribers,
    getListNewsletterVideoAcademy,
    generateNewsletterVideoAcademy,
    editNewsletterVideoAcademy,
    getNewsletters,
    getNewsletterForEdit,
    sendTestNewsletter,
    sendNewsletter,
    anteprimaNewsletter,
    deleteNewsletter,
    statusInvioNewsletter,
    newsletterModalOpen,
    sendNewsletterModalOpen,
    sendTestNewsletterModalOpen,
    openDeleteModalNewsletter,
    openEditModalNewsletter
}


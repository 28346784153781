import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'

export default inject("rootstore", "langstore")(observer((props) => {
    return (
        <div className="ts_view_content">
            <div className="row">
                <div className="col-12">
                    <h1>{props.langstore.t("ui_errortitle", "C'è stato un problema")}</h1>
                    <p>{props.langstore.t("ui_errorsubtitle", "Qualcosa non ha funzionato.")}</p>
                    <div onClick={() => props.rootstore.routeChange('/')} className="ts_button1 mt3">
                        <i className="fas fa-home"/>
                        {props.langstore.t('','Home')}
                    </div>
                </div>
            </div>
        </div>
    )
}));
const getDashboard = function (params, options) {
    if (this.userInfo.internal !== true) { // prevent unnecessary api calls if logged in as internal user
        this.getLastMessages();
        this.getNoteTecnicheDashboard();
        this.getWarrantiesToBeActivatedDashboardBox();
        this.videoAcademyDashboard();
    }
};

export {
    getDashboard
}
